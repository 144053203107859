import React from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import useHolidayStore from "../../../store/holidayStore";
import useTimesheetHook from "../../../hooks/useTimesheerHook";
import { useForm } from "react-hook-form";

export const HolidayModal = ({ isOpen, closeModal }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { addHoliday } = useHolidayStore();

  const { addHolidayMutation } = useTimesheetHook();

  const handleAddHoliday = (data) => {
    const { occupation, description, date } = data;

    addHolidayMutation.mutate({
      occupation: occupation,
      description: description,
      date: date,
    });

    addHoliday({
      occupation: occupation,
      description: description,
      date: date,
    });

    reset();

    closeModal();
  };

  return (
    <Dialog open={isOpen} onClose={closeModal} fullWidth maxWidth="sm">
      <DialogTitle>Add Holiday</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleAddHoliday)}>
          <div>
            <TextField
              label="Holiday Name"
              type="text"
              fullWidth
              {...register("occupation", {
                required: "Occupation is required",
                minLength: {
                  value: 3,
                  message: "Holiday Name must be at least 3 characters",
                },

                maxLength: {
                  value: 20,
                  message: "Holiday Name must not exceed 20 characters",
                },

                pattern: {
                  value: /^[a-zA-Z\s]*$/,
                  message: "Holiday Name must contain letters only",
                },

                validate: (value) =>
                  value.trim() !== "" || "Holiday Name is required",
              })}
              helperText={errors?.occupation?.message}
              error={errors?.occupation ? true : false}
              style={{ marginTop: "8px" }}
            />
          </div>

          <div>
            <TextField
              label="Description"
              type="text"
              fullWidth
              {...register("description", {
                required: "Description is required",
                minLength: {
                  value: 3,
                  message: "Description must be at least 3 characters",
                },

                maxLength: {
                  value: 20,
                  message: "Description must not exceed 20 characters",
                },

                pattern: {
                  value: /^[a-zA-Z\s]*$/,
                  message: "Description must contain letters only",
                },

                validate: (value) =>
                  value.trim() !== "" || "Description is required",
              })}
              helperText={errors?.description?.message}
              error={errors?.description ? true : false}
              style={{ marginTop: "8px" }}
            />
          </div>

          <div>
            <TextField
              label="Date"
              type="date"
              fullWidth
              // value={date}
              // onChange={(e) => setDate(e.target.value)}
              InputLabelProps={{
                shrink: true, // Keep the label above the input
              }}
              InputProps={{
                inputProps: {
                  min: new Date().toISOString().split("T")[0],
                },
                placeholder: "dd/mm/yyyy", // Custom placeholder text
              }}
              style={{ marginTop: "8px" }}
            />
          </div>

          <br />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleAddHoliday}
          >
            Add Holiday
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginLeft: "10px" }}
            onClick={closeModal}
          >
            Cancel
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
