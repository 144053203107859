import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}
export const handleKeyDown = (event) => {
  if (event.key === ' ') {
    event.preventDefault();
  }
};
export function formatDateTime(isoDateString) {
  const date = new Date(isoDateString);
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  return date.toLocaleDateString("en-US", options);
}
export function hexToRGBA(hex, opacity) {
  if (hex !== "" && hex !== undefined) {
    hex = hex.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
}
export const months = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];

export const getFileExtension = (url) => {
  const parts = url.split(".");
  if (parts.length > 1) {
    return parts.pop().split("?")[0]; // In case there are query parameters
  }
  return "";
};
export const getStatusStyle = (status) => {
  switch (status) {
    case "Completed":
      return { color: "green" ,cursor:'pointer'};
    case "InProgress":
      return { color: "#e3b717",cursor:'pointer' };
    case "Planning":
      return { color: "#f56c27",cursor:'pointer' };
      case"Draft":
      return{color:'blue',cursor:'pointer'}
      case"Pending":
      return{color:'red',cursor:'pointer'}
    default:
      return {color:'purple',cursor:'pointer'};
  }
};
export function formatProgress(progress) {
  // Convert to number if it's not already
  const numProgress = Number(progress);
  
  // Check if it's a valid number
  if (!isNaN(numProgress)) {
    return numProgress.toFixed(1);
  } else {
    return "Invalid progress value";
  }
}