import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { employeeServices } from "../service/employeeService";
import useEmployeeStore from "../store/employeeStore";
import { useDebounce } from "use-debounce";

const useEmployeeHook = (params = {}) => {
  const [debouncedParams] = useDebounce(params, 300);
  const fetchEmployees = async (params) => {
    const res = await employeeServices.getAllEmployee(debouncedParams);
    return res.employees;
  };
  const getEmployeeOfTheMonthHistory = async () => {
    const res = await employeeServices.getEmpofthemonthHistory(params);
    return res;
  };
  const fetchCredentialRequests = async () => {
    const res = await employeeServices.getCRedentialRequests();
    return res;
  };
  const fetchEmployeeProjectsMutation = useMutation(
    (data) => {
      return employeeServices.getemployeeProjects(data);
    },
    {
      onSuccess: (data) => {
        // updateEmployee(data);
        queryClient.invalidateQueries("employee");
      },
    }
  );
  const DeletePermenantlyMutation=useMutation(
    (data)=>{
      return employeeServices.DeleteUserPermenantly(data)
    } ,{
      onSuccess: (data) => {
        // updateEmployee(data);
        queryClient.invalidateQueries("deletedUsers");
      },
    }
  )
  const sendCredentialsMutation = useMutation(
    (data) => {
      return employeeServices.sendCredentials(data);
    },
    {
      onSuccess: (data) => {
        // updateEmployee(data);
        queryClient.invalidateQueries("fetchCredentialRequests");
      },
    }
  );
  const deleteEmployeeMUtation = useMutation(
    (data) => {
      return employeeServices.deleteEmployee(data);
    },
    {
      onSuccess: (data) => {
        // updateEmployee(data);
        queryClient.invalidateQueries("employee");
      },
    }
  );
  const fetchResponsibilities = async () => {
    const res = await employeeServices.getResponsibilities();

    return res;
  };

  const fetchSalary = async () => {
    const res = await employeeServices.getPayRolls();
    return res;
  };

  const fetchEmpMonth = async () => {
    const res = await employeeServices.getEmpMonth();
    return res;
  };
  const fetchTestersList = async () => {
    const res = await employeeServices.getTestersList();
    return res;
  };
  const fetchDepartmentList = async () => {
    const res = await employeeServices.getDepartmentList();
    return res.data.result;
  };
  const fetchDesignationList = async () => {
    const res = await employeeServices.getDesignationList();
    return res.data.data;
  };
  const fetchEmployeeById = async (id) => {
    const res = await employeeServices.getEmployeeById(id);
    return res;
  };
  const fetchDeletedUsers = async (id) => {
    const res = await employeeServices.fetchDeletedUsers(id);
    return res;
  };

  const { addEmployee, setTotalEmp, totalEmp, updateEmployee } =
    useEmployeeStore();
  const queryClient = useQueryClient();
  const { data, isLoading, isError, error } = useQuery(
    ["employee", debouncedParams.designation || "", debouncedParams.name || ""],
    () => fetchEmployees(debouncedParams),
    {
      enabled: !!params.data,
    }
  );
  const { data: EmployeeOfTheMonthHistory } = useQuery(
    ["EmployeeOfTheMonthHistory",params?.employeeId,params?.date],
    ()=>getEmployeeOfTheMonthHistory(params),
    {
      enabled: !!params.EmployeeOfTheMonthHistory,
    }
  );
  const { data: responsibilty } = useQuery(
    "responsibility",
    fetchResponsibilities,
    {
      enabled: !!params.responsibilty,
    }
  );
  const { data: credRequests } = useQuery(
    "fetchCredentialRequests",
    fetchCredentialRequests,
    {
      enabled: !!params.credRequests,
    }
  );

  const { data: testersList } = useQuery("testersList", fetchTestersList, {
    enabled: !!params.testersList,
  });

  const { data: departmentList } = useQuery(
    "departmentList",
    fetchDepartmentList,
    {
      enabled: !!params.departmentList,
    }
  );
  const { data: deletedUsers } = useQuery("deletedUsers", fetchDeletedUsers, {
    enabled: !!params.deletedUsers,
  });
  const { data: designationList } = useQuery(
    "designationList",
    fetchDesignationList,
    {
      enabled: !!params.designationList,
    }
  );
  const {
    data: salary,
    isError: salError,
    isLoading: salLoading,
  } = useQuery("salary", fetchSalary, {
    enabled: !!params.salary,
  });

  const { data: empMonth } = useQuery("empMonth", fetchEmpMonth, {
    enabled: !!debouncedParams.empMonth,
  });
  const { data: employeeById } = useQuery(
    ["empById", debouncedParams.id],
    () => fetchEmployeeById(debouncedParams.id),
    {
      enabled: !!debouncedParams.id,
    }
  );

  useEffect(() => {
    if (!isLoading && !isError) {
      addEmployee(data);
      setTotalEmp(data?.length);
    }
  }, [isLoading, isError]);

  const editEmployeeMutation = useMutation(
    (data) => {
      return employeeServices.updateEmployee(data.data, data.id);
    },
    {
      onSuccess: (data) => {
        updateEmployee(data);
        queryClient.invalidateQueries("employee");
        // toast.success("Employee updated successfully");
      },
    }
  );
  const revokeEmployeeProject = useMutation(
    (data) => {
      return employeeServices.revokeEmployeeProject(data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("employee");
        // toast.success("Employee updated successfully");
      },
    }
  );

  const addEmployeeMutation = useMutation(
    (data) => {
      return employeeServices.addEmployee(data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("employee");
        queryClient.invalidateQueries("departmentList");
        return data
      },
    }
    // async(data) => {
    //   const response =await employeeServices.addEmployee(data);
    //   return response;
    // },
    // {
    //   onSuccess: (response) => {
    //     if (response) {
    //       queryClient.invalidateQueries("employee");
    //       queryClient.invalidateQueries("departmentList");
    //       return response;
    //     }
    //   },
    // }
  );

  const deleteEmployeeMutation = useMutation(
    (id) => {
      return employeeServices.deleteEmployee(id);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("employee");
      },
    }
  );

  const addResponsibilitiesMutation = useMutation(
    (data) => {
      return employeeServices.addResponsibilities(data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("employee");
        queryClient.invalidateQueries("responsibility");
      },
    }
  );

  const takeResponsibilityBackMutation = useMutation(
    (data) => {
      return employeeServices.takeResponsibilityBack(data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("responsibility");
      },
    }
  );

  const addEmployeeOfMonthMutation = useMutation(
    (data) => {
      return employeeServices.addEmpMonth(data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("empMonth");
        queryClient.invalidateQueries("EmployeeOfTheMonthHistory");
      },
    }
  );

  const suspendEmployeeTillDateMutation = useMutation((data) => {
    return (
      employeeServices.suspendEmployeeTillDate(data),
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries("employee");
        },
      }
    );
  });

  const employeeActiveStatusMutation = useMutation((data) => {
    return (
      employeeServices.employeeActiveStatus(data),
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries("employee");
        },
      }
    );
  });

  return {
    data,
    isLoading,
    isError,
    error,
    editEmployeeMutation,
    addEmployeeMutation,
    deleteEmployeeMutation,
    totalEmp,
    addResponsibilitiesMutation,
    responsibilty,
    salError,
    salLoading,
    takeResponsibilityBackMutation,
    addEmployeeOfMonthMutation,
    empMonth,
    suspendEmployeeTillDateMutation,
    employeeActiveStatusMutation,
    salary,
    testersList,
    departmentList,
    employeeById,
    fetchEmployeeProjectsMutation,
    revokeEmployeeProject,
    designationList,
    deleteEmployeeMUtation,
    sendCredentialsMutation,
    credRequests,
    deletedUsers,
    EmployeeOfTheMonthHistory,
    DeletePermenantlyMutation
  };
};

export default useEmployeeHook;
