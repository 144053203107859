import React, { useEffect, useState } from "react";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import { MdClose } from "react-icons/md";
import Select from "react-select";
import EventModal from "./EventModal";
import moment from "moment";
import { Modal } from "@mui/material";

const MeetingModal = ({
  showModal,
  toggleModal,
  activeCategory,
  setReload,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [employees, setEmployees] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [showEventModal, setShowEventModal] = useState(false);
  const [allDay, setAllDay] = useState(false);
  const minDateTime = moment().format("YYYY-MM-DD HH:mm");
  useEffect(() => {
    handleAllEmployees();
  }, []);

  const handleAddMeeting = () => {
    let url;
    if (activeCategory === "meetings") {
      url = "/addMeeting";
    } else if (activeCategory === "holidays") {
      url = "/addHoliday";
    } else if (activeCategory === "events") {
      url = "/addOtherEvent";
    }
    axios
      .post(baseUrl + url, {
        category: activeCategory,
        title: title,
        description,
        date: dateTime,
        employees: selectedOption,
        occasion: title,
        allDay: allDay,
        endDate: endDateTime,
      })
      .then((res) => {
        setReload(url);
        toggleModal();
      })
      .catch((err) => {});
  };

  const handleAllEmployees = () => {
    axios
      .get(baseUrl + "/getAllEmployee")
      .then((res) => {
        const mapped = res.data.result.employees.map((result) => ({
          label: result?.name,
          value: result._id,
        }));
        setEmployees(mapped);
      })
      .catch((err) => {});
  };

  const handleChange = (selectedOptions) => {
    const filtered = selectedOptions.map((item) => item.value);
    setSelectedOption(filtered);
  };

  const toggleEventModal = () => {
    setShowEventModal(!showEventModal);
  };

  return (
    <>
    {(activeCategory === "meetings" ||
      activeCategory === "holidays" ||
      activeCategory === "events") && (
      <Modal
        open={showModal}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <div className="fixed inset-0 z-50 flex items-center justify-center  ">
            <div
              className="bg-white rounded-lg p-8 relative z-10 max-w-[400px] !min-w-[450px] !max-w-[450px]"
              style={{ boxShadow: "0px 3px 3px" }}
            >
              <div
                onClick={toggleModal}
                className="absolute top-2 right-2 cursor-pointer"
              >
                <MdClose size={24} />
              </div>
              <h2 className="text-xl font-bold mb-4 text-center capitalize">
                {activeCategory}
              </h2>
              <div>
                <input
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  style={{ borderBottom: "2px solid black" }}
                  type="text"
                  placeholder="Add title"
                />
              </div>

              <div className="flex h-[30px] items-center gap-2">
                <input
                  type="checkbox"
                  className="!w-[40px]"
                  onChange={(event) => setAllDay(event.target.checked)}
                />
                <span>All day</span>
              </div>
              {allDay && (
                <div style={{ marginTop: "1rem" }}>
                  <label>Date</label>
                  <input
                    onChange={(e) => setDateTime(e.target.value)}
                    value={dateTime}
                    type="date"
                    id="datetime"
                    name="datetime"
                    min={minDateTime}
                  />
                </div>
              )}
              {!allDay && (
                <div className="w-full flex gap-2">
                  <div style={{ marginTop: "1rem", width: "50%" }}>
                    <label>From</label>
                    <input
                      onChange={(e) => setDateTime(e.target.value)}
                      value={dateTime}
                      type="datetime-local"
                      id="datetime"
                      name="datetime"
                      min={minDateTime}
                    />
                  </div>
                  <div style={{ marginTop: "1rem", width: "50%" }}>
                    <label>To</label>
                    <input
                      onChange={(e) => setEndDateTime(e.target.value)}
                      value={endDateTime}
                      type="datetime-local"
                      id="endDateTime"
                      name="endDateTime"
                      min={minDateTime}
                    />
                  </div>
                </div>
              )}

              {activeCategory === "meetings" && (
                <div style={{ marginTop: "1rem" }}>
                  <Select
                    onChange={handleChange}
                    options={employees}
                    isMulti
                    className="multi_select"
                  />
                </div>
              )}

              <div style={{ marginTop: "1rem" }}>
                <textarea
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  style={{ width: "100%",padding:'10px' ,height: "15vh" }}
                  placeholder="Add Description"
                />
              </div>

              <div style={{ marginTop: "10px" }} className="flex gap-4">
                <button
                  onClick={handleAddMeeting}
                 
                  className=" bg-blue text-white px-4 py-2 rounded bg-[#3f51b5] cursor-pointer"
                >
                  {`  Save ${
                    activeCategory === "holidays"
                      ? "Holiday"
                      : activeCategory === "meetings"
                      ? "Meeting"
                      : activeCategory === "events"
                      ? "Event"
                      : "Event"
                  }`}
                </button>
                <button
                  onClick={toggleModal}
                  className=" bg-blue text-white px-4 py-2 rounded  bg-[#3f51b5] cursor-pointer"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
      </Modal>
        )}

      {/* {activeCategory!=='meetings' &&(
        <EventModal
          closeModal={toggleModal}
          reopenMeetingModal={toggleModal}
          activeCategory={activeCategory}
        />
      )} */}
    </>
  );
};

export default MeetingModal;
