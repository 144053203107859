import React from "react";
import { useLocation } from "react-router-dom";
import { DataTable } from "../../components/table/Data-Table";
import { columns } from "./columns";
import moment from "moment";

const ProjectHistory = () => {
  const location = useLocation();
  const data = location?.state?.project;
  return (
    <>
      <div className="uppercase text-xl font-semibold mb-4 p-2">{`${location?.state?.project?.projectName}'s History`}</div>
      <div className="w-full bg-white rounded-lg shadow-lg p-8 flex flex-col gap-2 mb-10">
        <h1 className="uppercase text-lg font-semibold border-b-2 border-grey">
          Details:
        </h1>
        <div className="w-full flex justify-between text-lg font-semibold">
          <div className="w-[30%] flex items-center justify-start ">
            Client Name
          </div>
          <div className="flex items-center justify-start w-[20%]">
            Start Date
          </div>
          <div className="flex items-center justify-start w-[20%]">
            End Date
          </div>
        </div>

        <div className="flex justify-between mb-[10px]">
          <div className="w-[30%] flex items-center justify-start">{` ${
            data?.clientName?.name ? data?.clientName?.name : "-"
          }`}</div>{" "}
          <div className="flex items-center justify-start w-[20%]">{`  ${moment(
            data?.projectStartDate
          ).format("DD-MM-yyyy")}`}</div>
          <div className="flex items-center justify-start w-[20%]">{`  ${moment(
            data?.projectEndDate
          ).format("DD-MM-yyyy")}`}</div>
        </div>
      </div>
      <div className="w-full bg-white rounded-lg shadow-lg p-8 flex flex-col gap-2 mb-10">
        <h1 className="uppercase text-lg font-semibold">Current Details:</h1>
        {data &&
          data?.mapped?.map((item) => (
            <div className="border-t-2 border-grey p-2 ">
              {item.teamType && (
                <>
                  <h1 className="mb-4 pb-2 border-b-[1px] border-grey">{`Team Name: ${item.teamType?.teamName}`}</h1>
                  <div className="w-full flex justify-evenly text-lg font-semibold">
                    <div className="w-[30%] flex items-center justify-center ">
                      Name
                    </div>
                    <div className="flex items-center justify-center w-[20%]">
                      Start Date
                    </div>
                    <div className="flex items-center justify-center w-[20%]">
                      End Date
                    </div>
                  </div>
                  <h1>Leader</h1>
                  <div className="flex justify-evenly mb-[10px]">
                    <div className="w-[30%] flex items-center justify-center">{` ${
                      item?.leaderId ? item?.leaderId?.name : "-"
                    }`}</div>{" "}
                    <div className="flex items-center justify-center w-[20%]">
                      {item?.leaderStartDate
                        ? moment(item?.leaderStartDate).format("DD-MM-yyyy")
                        : "-"}
                    </div>
                    <div className="flex items-center justify-center w-[20%]">
                      {item?.leaderEndDate
                        ? moment(item?.leaderEndDate).format("DD-MM-yyyy")
                        : "-"}
                    </div>
                  </div>
                  <div className="flex flex-col w-full">
                    <h1>Members</h1>
                    {item.members?.map((subItem) => (
                      <div>
                        <div className="flex justify-evenly mb-[10px]">
                          <div className="w-[30%] flex items-center justify-center">{` ${
                            subItem?.employeeId
                              ? subItem?.employeeId?.name
                              : "-"
                          }`}</div>{" "}
                          <div className="flex items-center justify-center w-[20%]">{` ${
                            subItem?.startDate
                              ? moment(subItem?.startDate).format("DD-MM-yyyy")
                              : "-"
                          }`}</div>
                          <div className="flex items-center justify-center w-[20%]">{` ${
                            subItem?.endDate
                              ? moment(subItem?.endDate).format("DD-MM-yyyy")
                              : "-"
                          }`}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          ))}
      </div>
      {/* <div className="w-full bg-white rounded-lg shadow-lg p-8 flex flex-col gap-2 mb-10">
        <h1 className="uppercase text-lg font-semibold">Past Details:</h1>
        {data &&
          data?.revokedUsers?.map((item) => (
            <div className="border-t-2 border-grey p-2 ">
              <h1 className="mb-4 pb-2 border-b-[1px] border-grey">{`Team Name: ${item.teamType?.teamName}`}</h1>
              <div className="w-full flex justify-evenly text-lg font-semibold">
                <div className="w-[30%] flex items-center justify-center">
                  Name
                </div>
                <div className="flex items-center justify-center w-[20%]">
                  Start Date
                </div>
                <div className="flex items-center justify-center w-[20%]">
                  End Date
                </div>
              </div>
              <h1>Leader</h1>
              <div className="flex justify-evenly mb-[10px]">
                <div className="w-[30%] flex items-center justify-center">{`  ${
                  item?.leaderId ? item?.leaderId?.name : "Deleted-User"
                }`}</div>{" "}
                <div className="flex items-center justify-center w-[20%]">{`  ${
                  item?.leaderStartDate
                    ? moment(item?.leaderStartDate).format("DD-MM-yyyy")
                    : "-"
                }`}</div>
                <div className="flex items-center justify-center w-[20%]">{` ${
                  item?.leaderEndDate
                    ? moment(item?.leaderEndDate).format("DD-MM-yyyy")
                    : "-"
                }`}</div>
              </div>
              <div className="flex flex-col w-full">
                <h1>Members</h1>
                {item.members?.map((subItem) => (
                  <div>
                    <div className="flex justify-evenly mb-[10px]">
                      <div className="w-[30%] flex items-center justify-center">{`  ${
                        subItem?.employeeId
                          ? subItem?.employeeId?.name
                          : "Deleted-User"
                      }`}</div>{" "}
                      <div className="flex items-center justify-center w-[20%]">{` ${
                        subItem?.startDate
                          ? moment(subItem?.startDate).format("DD-MM-yyyy")
                          : "-"
                      }`}</div>
                      <div className="flex items-center justify-center w-[20%]">{` ${
                        subItem?.endDate
                          ? moment(subItem?.endDate).format("DD-MM-yyyy")
                          : "-"
                      }`}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div> */}
    </>
  );
};

export default ProjectHistory;
