import React, { useEffect, useMemo, useState } from "react";
import { DataTable } from "../components/table/Data-Table";
import axios from "../config/axiosAuth";
import { baseUrl } from "../config/baseUrl";
import "./page.css";
import { useNavigate } from "react-router-dom";
import { DescriptionModal } from "../components/modals/DescriptionModal";
import moment from "moment";
import CommonAlert from "../components/ui/CommonAlert";
import { Delete, Trash } from "lucide-react";
import { EditHolidayModal } from "../pages/DetailedTimesheet/Modals/EditHolidayModal";

const Page = ({meetings,handleallevents}) => {
  // const [meeting, setMeeting] = useState();

  // const handleAllmeeting = () => {
  //   axios
  //     .get(baseUrl + "/allMeetings")
  //     .then((res) => {
  //       setMeeting(res.data);
  //     })

  //     .catch((err) => {
  //     });
  // };
const navigate=useNavigate()
  // useEffect(() => {
  //   handleAllmeeting();
  // }, []);
  

  const JoinMeetingClick=(id)=>{
    const data={linkId:id.linkId,
      id:id._id
    }
   navigate(`/meet/${id.linkId}`,{
    state: data
    
  })
}

const deleteMeeting=async(id)=>{
  await axios.post(`${baseUrl}/deleteMeetings`,{_id:id});
  handleallevents()

}
const columns =useMemo(()=>[
  {
    header: "S.No",
    cell: ({ row }) => {
      return <span>{row.index + 1}</span>;
    },
  },
  {
    accessorKey: "title",
    header: "Title",
  },

  {
    accessorKey: "date",
    header: "Date-Time",
    cell:({row})=>(moment.utc(row.original?.date).format('DD-MM-YYYY HH:mm'))
  },

  {header:'Description',
    cell:({row})=> <DescriptionModal trigger={<span className="flex w-[100px] overflow-hidden truncate">{`${row.original.description.substring(0,14)}....`}</span>} data={row.original.description}/>

  },
  {
    header: "Meeting",
    cell: ({ row }) => {
      return (
        <span
          style={{
            width: "10vw",
            padding:"6px",
            background: "#085394e6",
            color: "white",
            borderRadius: "5px",
            cursor:"pointer"
          }}
          onClick={()=>{return(
            JoinMeetingClick(row.original))}}
        >
          Join Meeting
        </span>
      );
    },
  },
  {
    header: "Edit",
    cell: ({ row }) =><EditHolidayModal id={row?.original?._id} recall={handleallevents}activeCategory='Meeting'data={row?.original}/>,
  },
  {
    header: "Delete",
        cell: ({ row }) => <CommonAlert trigger={<Trash  style={{color:'red',margin:'auto'}}/>}onClick={() => deleteMeeting(row.original?._id)}  heading={"Delete"} text={"Are you sure you want to delete ?"}/>
  },
],[meetings]);

  return (
    <div>
          {/* <div style={{display:"flex",justifyContent:"flex-end"}}>
        <button type="btn" className="join_meeting_btn">Join Meeting</button>
      </div> */}
      <div>
        <DataTable heading={"Meetings"}columns={columns} data={meetings||[]} defaultTheme={true} TableHeight={'200px'}/>
      </div>

    
    </div>
  );
};

export default Page;
