import React, { useState } from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

// import {
//   AlertDialog,
//   AlertDialogAction,
//   AlertDialogCancel,
//   AlertDialogContent,
//   AlertDialogDescription,
//   AlertDialogFooter,
//   AlertDialogHeader,
//   AlertDialogTitle,
//   AlertDialogTrigger,
// } from "../SharedUiComponents/Dialog/alert-dialog";
// import { cn } from "../../Utils/HelperFunctions";
import { useNavigate } from "react-router-dom";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "./alert-dialog";
import { cn } from "../../../utils/lib";

const LogoutAlert = ({onClick,className, refprop}) => {
  const[open ,setOpen]=useState(false)
// const{ref}=useComponentVisible()


    const navigate=useNavigate()
    const logout=()=>{
        localStorage.clear();
        navigate('/login')
      }
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild className="w-[75px]">
          <p className={cn("lg:text-white text-white text-left cursor-pointer",className)} onClick={()=>setOpen(true)}> <ExitToAppIcon className="icon" />Logout</p>
      </AlertDialogTrigger>

      <AlertDialogContent className='bg-white'>
        <AlertDialogHeader>
          <AlertDialogTitle className='font-bold text-2xl'>Logout </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogDescription>
          Are you sure you want to Logout ?
        </AlertDialogDescription>
        <AlertDialogFooter ref={refprop}>
          <AlertDialogCancel  ref={refprop} className={cn(buttonstyles,'bg-errorPrimary')}>Cancel</AlertDialogCancel>
          <AlertDialogAction ref={refprop} onClick={logout} className={cn(buttonstyles,'bg-successPrimary')}>LOGOUT </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default LogoutAlert;
const buttonstyles = "w-[110px] !h-[35px] !hover:text-[white] rounded-[8px] !min-h-[35px] border-none !text-white text-[14px] font-medium font-md uppercase !bg-[#2c3e50]";
