/* eslint-disable no-unused-vars */
import EditNoteIcon from "@mui/icons-material/EditNote";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../config/axiosAuth";
import { columns } from "./columns";
import { DataTable } from "../../components/table/Data-Table";
import Loading from "../protectedPages/Loading";
import useEmployeeHook from "../../hooks/useEmployeeHook";

const Report = () => {

  const { data: employees, isLoading: employeeLoading } = useEmployeeHook({data:true,designation:'',name:''});

  

  return <>
  
    <DataTable columns={columns} data={employees||[]}defaultTheme={true} TableHeight={'350px'} heading='Employee Wise Reports'/>
    </>
}

export default Report;
