// Layout.js
import React from "react";

import "./home.scss";
import { AdminSIdeBar } from "./components/sidebar/AdminSIdeBar";
import Navbar from "./components/navbar/Navbar";



const Layout = ({ children }) => {
  const path = window.location.pathname;

  // const formattedPath = formatPath(path.substring(1));

  return (
    <div className="home">
      <AdminSIdeBar />
      <div className="homeContainer">
        <Navbar />

        <div className="layout_section">
          <div
            className=""
            style={{
              width: "100%",
              height: "calc(100% - 0px)",
              // overflow: "auto",
              padding:'0px 10px'
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout;
