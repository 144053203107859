import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import useProjectHook from "../../hooks/useProjectHook";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { Button } from "../../components/ui/button";
import { useLocation, useParams } from "react-router-dom";
import useGetOneProject from "../../hooks/getProjectHook";
import Loading from "../protectedPages/Loading";
import ReactSelect from "react-select";
import { baseUrl } from "../../config/baseUrl";
import axios from "../../config/axiosAuth";
import { DataTable } from "../../components/table/Data-Table";
import { columns } from "../addProject/Columns";
import PreviewUrlStore from "../../store/previewUrlStore";
import { PreviewDocument } from "../projects/PreviewUploadedDocumentsDialog";
import { AssignModule } from "../addProject/AssignModuleDialog";
import { Delete } from "lucide-react";
import { cn, getFileExtension } from "../../../utils/lib";
import moment from "moment";
import { EmployeeList } from "../team/EmployeeList";
import NestedFields from "./nestedFields";
import { SelectCustomStyles } from "../../../utils/styles";
import CommonAlert from "../../components/ui/CommonAlert";

const AddProjectNew = () => {
  const params = useParams();
  // const id = params.projectId;
  let state = useLocation();
  const formRef = useRef(null);
  const { previewUrls, setPreviewUrl } = PreviewUrlStore();
  const [saveDraft, setSaveDraft] = useState(false);
  const { addProjectMutation } = useProjectHook();
  const submitRef = useRef(false);
  const [teamMembers, setTeamMebers] = useState([]);
  const fileInputRef = useRef('');

  const { register, handleSubmit, setValue, watch, formState, control, reset } =
    useForm({
      defaultValues: {
        projectName: state.state?.projectName,
        clientName: state.state?.clientName,
        projectStartDate: state.state?.startDate,
        projectEndDate: state.state?.endDate,
        projectId: state?.state?.projectId,
      },
    });
  const { fields, append, prepend, remove:removeTeam, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "teams",
    });

  const { editProjectMutation } = useProjectHook();
  const { data: employees } = useEmployeeHook({data:true,designation:'',name:""});
  const { departmentList } = useEmployeeHook({departmentList:true});

  let renderCount = 0;
  const [project, setProject] = useState([]);
  const [modules, setModules] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const onSubmit = (data) => {
    if (submitRef.current === true) {
      const savedraftData = { ...data, status: "Draft" };
      if (saveDraft) {
        addProjectMutation.mutate(savedraftData);
      } else {
        addProjectMutation.mutate(data);
      }
      reset();
    }
  };
  const handleclick = () => {
    submitRef.current = true;
  };
  useEffect(() => {
    if (state?.state?.modules) {
      setModules(
        state?.state?.modules?.map((item) => ({ label: item, value: item }))
      );
    }
  }, [state]);

  const { errors } = formState;
  useEffect(() => {
    if (departmentList) {
      setDepartmentOptions(
        departmentList?.map((item) => ({
          value: item._id,
          label: item.teamName,
        }))
      );
    }
  }, [departmentList]);

  useEffect(() => {
    if (employees) {
      setEmployeeOptions(
        employees?.map((item) => ({ value: item._id, label: item.name }))
      );
    }
  }, [employees]);

  const [fileError, setFileError] = useState("");
  const [fileToUpload, setFileToUpload] = useState(null);
  const [documentsArray, setDocumentArray] = useState([]);

  const handleSaveClick = (isDraft) => {
    handleclick();
    setSaveDraft(isDraft);
    // saveDraftRef.current = isDraft;
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.requestSubmit();
      }
    }, 0);
  };
  useEffect(() => {}, [documentsArray]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    // Check file type
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/png",
      "image/gif",
    ];
    if (!allowedTypes.includes(file?.type)) {
      setFileError("Please upload a PDF, Word document, or image file.");

      return;
    }

    // Check file size (34 MB = 34 * 1024 * 1024 bytes)
    const maxFileSize = 25 * 1024 * 1024;
    if (file.size > maxFileSize) {
      setFileError("File size should not exceed 25 MB.");
      return;
    }

    // If file passes validation, proceed with upload or further processing
    setFileError("");
    // setDocumentArray([{document:file,documentType:file?.type},...documentsArray ]);

    handleuploaddocumnet(file);

    // Your file upload logic here
  };
  useEffect(() => {}, [documentsArray]);
  useLayoutEffect(() => {
    return () => {
      deleteTempUrl();
    };
  }, []);
  const deleteTempUrl = (saveUrl) => {
    const data = {
      projectId:  state?.state?.projectId,
      saveUrl: saveUrl ? true : false,
    };
    axios
      .post(baseUrl + "/delete-temp-url", data, {
       
      })

      .then((res) => {
      fileInputRef.current.value = "";

        setPreviewUrl(res.data.project.tempUrls);
      })

      .catch((err) => {
      });
  };
  const handleuploaddocumnet = (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("projectId", state.state.projectId);
    axios
      .post(baseUrl + "/upload", data, {
        headers: {
          "Content-Type": "multi-part/form-data",
        },
      })

      .then((res) => {
        setPreviewUrl(res.data.project.tempUrls);
      })

      .catch((err) => {
      });
  };
  const deleteFile = async (url) => {
    const urlPath = window.location.pathname;
    const segments = urlPath.split("/");
    const id = state?.state?.projectId; // Assuming the ID is the last segment
    try {
      const response = await axios.post(`${baseUrl}/delete-module`, {
        fileUrl: url,
        projectId: id,
      });
      setPreviewUrl(response.data.project.tempUrls);
    } catch (err) {}
  };
  const PreviewColumns = useMemo(
    () => [
      {
        header: "S.No",
        cell: ({ row }) => {
          return <span>{row.index + 1}</span>;
        },
      },
      {
        header: "Attched Document",
        cell: ({ row }) => row?.original?.file_name,
      },

      {
        accessorKey: "Attcahed Document Type",
        cell: ({ row }) => getFileExtension(row?.original?.url),
      },
      {
        header: "Assign Module",
        cell: ({ row }) => <AssignModule file={row?.original?.url} previousModules={row?.original?.module_type}/>,
      },

      {
        header: "Preview",
        cell: ({ row }) => (
          <PreviewDocument
            fileType={row?.original?.url}
            path={row?.original?.url}
          />
        ),
      },
      {
        header: "Actions",
        cell: ({ row }) => <CommonAlert trigger={<Delete  style={{color:'red',margin:'auto'}}/>}onClick={() => deleteFile(row.original?.url)}  heading={"Delete"} text={"Are you sure you want to delete ?"}/>,
      },
    ],
    []
  );

  const validateProjectDate = () => {
    const projectStartDate = watch("projectStartDate");
    const projectEndDate = watch("projectEndDate");
    const projectSubmitDate = watch("projectSubmitDate");

    if (projectStartDate && projectEndDate && projectSubmitDate) {
      if (
        new Date(projectStartDate) > new Date(projectEndDate) ||
        new Date(projectEndDate) > new Date(projectSubmitDate)
      ) {
        return "Project Start Date should be less than Project End Date and Project End Date should be less than Project Submit Date";
      }
    }
  };

  const getTeamMebers = async (teamId, index) => {
    try {
      const response = await axios.post(baseUrl + "/team-members", {
        id: teamId,
      });
      setTeamMebers((prev) => ({
        ...prev,
        [index]: response.data?.result?.teamMembers?.map((item) => ({
          value: item._id,
          label: item.name,
        })),
      }));
    } catch (err) {
      throw new Error(err);
    }
  };
  // if (getProject.isLoading) return <Loading />;
  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor="projectName">Project Name:</label>
          <input
            type="text"
            {...register("projectName", {
              pattern: {
                value: /^[a-zA-Z0-9 ]*$/,
                message: "Only alphabets and numbers are allowed",
              },
              maxLength: {
                value: 120,
                message: "Maximum 15 characters are allowed",
              },

              minLength: {
                value: 3,
                message: "Minimum 3 characters are allowed",
              },
            })}
          />

          {errors.projectName && (
            <p className="error">{errors.projectName.message}</p>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="clientName">Client Name:</label>
          <input
            readOnly
            type="text"
            value={state?.state?.name}
            // {...register("clientName", {
            //   pattern: {
            //     value: /^[a-zA-Z0-9 ]*$/,
            //     message: "Only alphabets and numbers are allowed",
            //   },
            //   maxLength: {
            //     value: 15,
            //     message: "Maximum 15 characters are allowed",
            //   },

            //   minLength: {
            //     value: 3,
            //     message: "Minimum 3 characters are allowed",
            //   },
            // })}
          />

          {errors.clientName && (
            <p className="error">{errors.clientName.message}</p>
          )}
        </div>
      </div>

      <div className="form-row">
        <div className="form-group">
          <label htmlFor="projectStartDate">Project Start Date:</label>
          <input
            type="date"
            min={moment(state.state?.startDate).format("YYYY-MM-DD")}
            max={moment(state.state?.endDate).format("YYYY-MM-DD")}
            id="projectStartDate"
            name="projectStartDate"
            value={watch("projectStartDate")}
            onChange={(e) => setValue("projectStartDate", e.target.value)}
            {...register("projectStartDate", {
              required: "please Select a date",
              validate:!saveDraft && validateProjectDate,
            })}
          />

          {errors.projectStartDate && (
            <p className="error">{errors.projectStartDate.message}</p>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="projectEndDate">Project End Date:</label>
          <input
            type="date"
            min={moment(state?.state?.startDate).format("YYYY-MM-DD")}
            id="projectEndDate"
            name="projectEndDate"
            value={watch("projectEndDate")}
            onChange={(e) => setValue("projectEndDate", e.target.value)}
            {...register("projectEndDate", {
              required: "please Select a date",
              validate:!saveDraft && validateProjectDate,
            })}
          />

          {errors.projectEndDate && (
            <p className="error">{errors.projectEndDate.message}</p>
          )}
        </div>
      </div>
      <ul>
        {fields.map((item, index) => {
          return (
            <li key={item.id}>
              <div className="form-row">
                <div className="form-group">
                  <Controller
                    rules={{
                      required: "Please select a team",
                    }}
                    render={({ field }) => (
                      <ReactSelect
                        styles={SelectCustomStyles}
                        isClearable
                        onChange={(option) =>{
                          return(
                          field.onChange(option ? option.value : null),
                          getTeamMebers(option?.value ,index))}
                        }
                        value={
                          departmentOptions.find(
                            (option) => option.value === field.value
                          ) || null
                        }
                        options={departmentOptions}
                        placeholder={"Select a team ...."}
                      />
                    )}
                    name={`teams.${index}.teamType`}
                    control={control}
                  />
                  { errors?.teams?.[index]?.teamType?.message&&<p  className="error-message"> {errors?.teams?.[index]?.teamType?.message}</p>}
                </div>
                <div className="form-group">
                  <Controller
                    rules={{
                      required: "Please select a Leader",
                    }}
                    render={({ field }) => (
                      <ReactSelect
                        onChange={(val) => {
                          return (
                            field.onChange(val ? val.value : null)
                          );
                        }}
                        styles={SelectCustomStyles}
                        isClearable
                        value={
                          teamMembers?.[index]?.find(
                            (option) => option.value === field.value
                          ) || null
                        }
                        options={teamMembers[index]}
                        placeholder={"Select a Leader ...."}
                      />
                    )}
                    name={`teams.${index}.leaderId`}
                    control={control}
                  />
                  { errors?.teams?.[index]?.leaderId?.message&&<p  className="error-message"> {errors?.teams?.[index]?.leaderId?.message}</p>}

                </div>
                <div className="form-group">
                  <Controller
                    rules={{
                      required: "Please select Modules",
                    }}
                    render={({ field }) => (
                      <ReactSelect
                        isMulti
                        styles={SelectCustomStyles}
                        isClearable
                        onChange={(selectedOptions) => {
                          const selectedValues = selectedOptions
                            ? selectedOptions.map((option) => option.value)
                            : [];
                          field.onChange(selectedValues);
                        }}
                        value={modules.filter(
                          (option) =>
                            field.value && field.value.includes(option.value)
                        )}
                        options={modules}
                        placeholder={"Select modules ...."}
                      />
                    )}
                    name={`teams.${index}.leaderModules`}
                    control={control}
                  />
                  { errors?.teams?.[index]?.leaderModules?.message&&<p  className="error-message"> {errors?.teams?.[index]?.leaderModules?.message}</p>}

                </div>
                <div className="form-group">
                  <input
                    type="date"
                    min={moment(state.state?.startDate).format("YYYY-MM-DD")}
                    max={moment(state.state?.endDate).format("YYYY-MM-DD")}
                    id={`teams.${index}.leaderStartDate`}
                    name={`teams.${index}.leaderStartDate`}
                    value={watch("leaderStartDate")}
                    onChange={(e) =>
                      setValue(`teams.${index}.leaderStartDate`, e.target.value)
                    }
                  />

{ errors?.teams?.[index]?.leaderStartDate?.message&&<p  className="error-message"> {errors?.teams?.[index]?.leaderStartDate?.message}</p>}
                  
                </div>

                <div className="form-group">
                  <input
                    type="date"
                    id={`teams.${index}.leaderEndDate`}
                    min={watch(`teams.${index}.leaderStartDate`)}
                    max={moment(state.state?.endDate).format("YYYY-MM-DD")}
                    name={`teams.${index}.leaderEndDate`}
                    value={watch(`teams.${index}.leaderEndDate`)}
                    onChange={(e) =>
                      setValue(`teams.${index}.leaderEndDate`, e.target.value)
                    }
                    {...register(`teams.${index}.leaderEndDate`, {})}
                  />

{ errors?.teams?.[index]?.leaderEndDate?.message&&<p className="error-message"> {errors?.teams?.[index]?.leaderEndDate?.message}</p>}
                 
                </div>
              </div>

              <NestedFields
                errors={errors}
                nestIndex={index}
                removeTeam={removeTeam}
                {...{ control, register }}
                employeeOptions={teamMembers?.[index]}
                modules={modules}
                watch={watch}
                setValue={setValue}
                project={project}
                projectStartDate={state?.state?.startDate}
                projectEndDate={state?.state?.endDate}
              />
            </li>
          );
        })}
      </ul>
      <hr />
      <button
        type="button"
        onClick={() => append({})}
        className="flex items-center justify-center mt-[10px] gap-2 mb-[30px]"
      >
        <div
          className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff]"
          style={{ backgroundColor: "rgb(8,83,148,0.3)" }}
        >
          +
        </div>
        Team
      </button>
      <div
        style={{
          width: "100%",
        }}
      >
        <textarea
          id="projectDescription"
          name="projectDescription"
          value={watch("projectDescription")}
          onChange={(e) => setValue("projectDescription", e.target.value)}
          {...register("projectDescription", {
            required: !saveDraft && "Please enter a project description",

            maxLength: {
              value: 150,
              message: "Maximum 150 characters allowed",
            },

            minLength: {
              value: 10,
              message: "Minimum 10 characters allowed",
            },

            // validate: (value) =>
            //   !saveDraft &&
            //   (value.trim() !== "" || "Input cannot be only whitespaces"),
          })}
          placeholder="Project Description"
          className="p-1"
        ></textarea>

        {errors.projectDescription && (
          <p className="text-xs text-red-500">
            {errors.projectDescription.message}
          </p>
        )}
      </div>

      <div className="w-[100%] h-[20vh] flex items-end gap-2">
        <div className="flex items-end gap-4 mb-[30px]">
          <div>
            <label>Upload Document</label>
            <input
              // onClick={handleUploadimage}
              style={{ height: "5vh" }}
              type="file"
              multiple
              ref={fileInputRef}
              id="img"
              name="img"
              onChange={handleFileUpload}
              accept=".pdf,.doc,.docx,.jpg,.png,.jpeg,.gif"
              className="min-w-[150px] max-w-[250px]"
            />
          </div>
        </div>

        {fileError && <p style={{ color: "red" }}>{fileError}</p>}
      </div>
      <DataTable
        columns={PreviewColumns}
        data={previewUrls || []}
        defaultTheme={true}
        TableHeight={"200px"}
        heading="Preview uploaded Documents"
      />
      {/* <button
        onClick={deleteTempUrl}
        type="btnnnnn"
        style={{
          backgroundColor: "#085394",
          color: "white",
          border: "none",
          width: "6vw",
          borderRadius: "5px",
          marginTop: "5px",
          height: "45px",
          minWidth: "150px",
        }}
      >
        Upload
      </button> */}
       <button
        onClick={() => deleteTempUrl(true)}
        type="text"
        style={{
          backgroundColor: "#085394",
          color: "white",
          border: "none",
          width: "6vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
          marginTop: "5px",
          height: "45px",
          minWidth: "150px",
          marginBottom: "30px",
        }}
        disabled={previewUrls?.length<=0}
        className={cn({"cursor-not-allowed":previewUrls?.length<=0})}
      >
        Upload
      </button>
      {/* <DataTable
          columns={columns}
          data={documentsArray}
          defaultTheme={true}
          TableHeight={"200px"}
          heading="Uploaded Documents List"
        /> */}
      <Button
        className="mr-[20px]"
        type="button"
        ref={submitRef}
        style={{
          backgroundColor: "#085394",
          color: "white",
          border: "none",
          width: "6vw",
          borderRadius: "5px",
          marginTop: "5px",
          height: "45px",
          minWidth: "150px",
          marginLeft: "20px",
        }}
        onClick={() => handleSaveClick(false)}
      >
        Save
      </Button>
      <Button
        type="button"
        onClick={() => handleSaveClick(true)}
        style={{
          backgroundColor: "#085394",
          color: "white",
          border: "none",
          width: "6vw",
          borderRadius: "5px",
          marginTop: "5px",
          height: "45px",
          minWidth: "150px",
        }}
        ref={submitRef}
      >
        Save As Draft
      </Button>
    </form>
  );
};

export default AddProjectNew;
const style = {
  control: (baseStyles, state) => ({
    ...baseStyles,

    height: "40px",
    border: " 1px solid #ccc",
    borderRadius: "5px",
  }),
};
