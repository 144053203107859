import { useEffect, useMemo, useState } from "react";
import useTimesheetHook from "../../hooks/useTimesheerHook";
import { CircularProgress, Pagination, Typography, Stack } from "@mui/material";
import useEventStore from "../../store/eventStore";
import { baseUrl } from "../../config/baseUrl";
import { DataTable } from "./Data-Table";
import moment from "moment";
import { DescriptionModal } from "../modals/DescriptionModal";
import CommonAlert from "../ui/CommonAlert";
import { Trash } from "lucide-react";
import { EditHolidayModal } from "../../pages/DetailedTimesheet/Modals/EditHolidayModal";
import axios from "axios";
import { useQueryClient } from "react-query";

const EventTable = ({handleallevents}) => {
  const { otherEvents } = useEventStore();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3);

  // const [eventList,setEventList]=useState();
  const queryClient = useQueryClient();
  const deleteEvent = async (id) => {
    await axios.post(`${baseUrl}/deleteEvents`,{_id:id});
    handleallevents()
  };

  const columns = useMemo(
    () => [
      { header: "S.No", cell: ({ row }) => row.index + 1 },
      { header: "Title", cell: ({ row }) => row.original?.title },
      {
        header: "Description",
        cell: ({ row }) => (
          <DescriptionModal
            trigger={
              <span className="flex w-[100px] overflow-hidden truncate">{`${row.original.description.substring(
                0,
                14
              )}....`}</span>
            }
            data={row.original.description}
          />
        ),
      },
      {
        header: "Date",
        cell: ({ row }) => moment.utc(row.original?.date).format("DD-MM-YYYY"),
      },
      {
        header: "Edit",
        cell: ({ row }) => (
          <EditHolidayModal
            id={row?.original?._id}
            activeCategory="Event"
            data={row?.original}
            recall={handleallevents}
          />
        ),
      },
      {
        header: "Delete",
        cell: ({ row }) => (
          <CommonAlert
            trigger={<Trash style={{ color: "red", margin: "auto" }} />}
            onClick={() => deleteEvent(row.original?._id)}
            heading={"Delete"}
            text={"Are you sure you want to delete ?"}
          />
        ),
      },
    ],
    [otherEvents]
  );

  return (
    <>
      {/* <div>
        <div
          className="table-container"
        p
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "2px solid gray",
              marginBottom: "10px",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                fontWeight: "bold",
                marginLeft:"10px"
              }}
            >
              Event
            </h5>
          </div>
          <table>
            <thead
              style={{
                color: "#c0c3c7",
                fontWeight: "bold",
              }}
            >
              <tr
                style={{
                  backgroundColor: "#f1f4f7",
                }}
              >
                <th>S.No.</th>
                <th>Description</th>
                <th>Subject</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody className="tableBody">
              {otherEvents !== "" && otherEvents?.length > 0 ? (
                renderTable()
              ) : (
                <tr>
                  <td colSpan="12">No project present</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      
      </div> */}
      <DataTable
        columns={columns}
        data={otherEvents || []}
        defaultTheme={true}
        TableHeight={"400px"}
        heading={"Events"}
      />
    </>
  );
};

export default EventTable;
