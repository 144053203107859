import React, { useEffect, useState } from "react";
import { LuUserCircle2, LuUserPlus2 } from "react-icons/lu";
import { IoPersonRemove } from "react-icons/io5";

import { InvitePeopleToCallModal } from "../../components/modals/InvitepeopleToCall";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { EmployeeList } from "../../components/EmployeeList";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
} from "@mui/material";
import { ListIcon } from "lucide-react";
import { MdMicOff } from "react-icons/md";

const PeopleSection = ({ roomId, kickUserFromRoom ,muteUser}) => {
  const [usersInvitedToCall, setUsersInvitedToCall] = useState([]);
  const [showAddPeoplePopup, setAddPeoplePopup] = useState(false);
  const [searchedEmployeeName, setSearchedEmployeeName] = useState("");
  const [invitedPeople, setInvitedPeople] = useState([]);
  const { data: employees } = useEmployeeHook();
  const [searchInput, setSearchInput] = useState("");

  const CloseAddPeoplePopUp = () => {
    setAddPeoplePopup(false);
  };
  const location = useLocation();
  const LinkId = location.pathname.split("");
  const InvitePeopleTocall = (addedEmployee) => {
    const employeeExists = invitedPeople.find(
      (item) => item.id === addedEmployee._id
    );
    if (employeeExists) {
      const filteredEmployeeList = invitedPeople.filter(
        (item) => item.id !== addedEmployee._id
      );
      setInvitedPeople(filteredEmployeeList);
    } else {
      setInvitedPeople((prev) => [
        ...prev,
        { name: addedEmployee.name, id: addedEmployee._id },
      ]);
    }
  };

  const searchedEmployee = (event) => {
    setSearchedEmployeeName(event.target.value);
  };
  useEffect(() => {
  }, [invitedPeople]);

  const updatedList = employees?.filter((employee) => {
    return employee.name.toLowerCase().includes(searchInput.toLowerCase());
  });
  const filteredEmployeeIntheCall = usersInvitedToCall?.filter((employee) => {
    return employee.name
      .toLowerCase()
      .includes(searchedEmployeeName.toLowerCase());
  });

  useEffect(() => {}, []);
  const AddPeopleButtonClick = () => {
    setAddPeoplePopup(true);
  };
  const PeoplePresentIntheCall = async () => {
    try {
      const response = await axios.get(baseUrl + `/meeting/${roomId.id}`);
      //   setAddPeoplePopup(false);
      setUsersInvitedToCall(response.data.employees);

    } catch (error) {
      // toast.error(error.response.data?.message);
    }
  };

  useEffect(() => {
    PeoplePresentIntheCall();
  }, []);

  const AddPeopleToTheCall = async () => {
    try {
      const response = await axios.put(baseUrl + "/editMeeting", {
        linkId: roomId.linkId,
        employees: invitedPeople?.map((item) => item.id),
      });
      // toast.success(response.data.message);
      setAddPeoplePopup(false);
      setInvitedPeople([]);
    } catch (error) {
      // toast.error(error.response.data?.message);
    }
  };

  return (
    <>
      <div className="people_head">People</div>
      <>
        <div className="add_people_button">
          {/* <LuUserCircle2 /> */}
          <LuUserPlus2 color="#0054a6" onClick={AddPeopleButtonClick} />
          <p>add People</p>
        </div>
        <div
          style={{
            width: "100%",
            height: "75px",
            display: "flex",
            alignItems: "flex-end",
          }}
          className="search_container"
        >
          <TextField
            fullWidth
            label="Search Employee to give invite"
            variant="standard"
            value={searchedEmployeeName}
            onChange={searchedEmployee}
          />
        </div>
        <div className="all_people_invited_section">
          <List>
            {filteredEmployeeIntheCall?.map((employee) => (
              <ListItem
                className="list_item_employee"
               
              >
                <ListItemAvatar>
                  <Avatar>{employee.name[0]}</Avatar>
                </ListItemAvatar>

                <ListItemText>{employee.name}</ListItemText>

                <Tooltip title={`Mute ${employee.name}`}>
                  <ListItemIcon onClick={()=>muteUser(employee._id)}>
                    <MdMicOff  color='white'/>
                  </ListItemIcon>
                </Tooltip>

                <Tooltip title={`Remove ${employee.name} from call`}>
                  <ListItemIcon
                   onClick={() => {
                    kickUserFromRoom(employee._id);
                  }}>
                    <IoPersonRemove color="white" />
                  </ListItemIcon>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </div>
      </>
      <InvitePeopleToCallModal
        showAddPeoplePopup={showAddPeoplePopup}
        closeAddPeoplePopup={CloseAddPeoplePopUp}
        Allemployees={updatedList}
        InvitePeopleTocall={InvitePeopleTocall}
        setSearchInput={setSearchInput}
        invitedPeople={invitedPeople}
        handleSubmit={AddPeopleToTheCall}
      />
    </>
  );
};

export default PeopleSection;
