import React from 'react'
import { Button } from '../../components/ui/button'
import { GiTeamIdea } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';
import AllocationList from './AllocationList';

const Allocations = () => {
    const navigate=useNavigate()
  return (
    <div className='w-full h-full flex flex-col '>
        <Button onClick={()=>navigate('/addAllocation')} className='mb-10 !max-w-max w-[150px]'>
            Add Alocation <GiTeamIdea style={{marginLeft:'10px'}}/>
        </Button>
        <div className='w-full h-[90%] overflow-auto'>

        <AllocationList/>
        </div>
      
    </div>
  )
}

export default Allocations
