import React, { useMemo } from "react";
import { DataTable } from "../../components/table/Data-Table";
import useClientHook from "../../hooks/useClientHook";
import Error from "../protectedPages/Error";
import Loading from "../protectedPages/Loading";
import { AddModal } from "./AddModal";
import moment from "moment";
import { CommentDialog } from "../../components/modals/CommentDialog";
import { useNavigate } from "react-router-dom";
import { EditClientDialog } from "./EditClientModal";
import { TruncatedCell } from "./columns.jsx";
import { TeamMemberDetails } from "../team/TeamMemberDtails.jsx";
// import  {columns} from './columns.jsx'

import { Eye } from "lucide-react";
import { MoreDetails } from "./Moredetails.jsx";

const Client = () => {
  const { data, isLoading, isError } = useClientHook();
  const navigate = useNavigate();
  //  const columns = [
  //     {
  //       header: "S.No",
  //       cell: ({ row }) => {
  //         return <span>{row.index + 1}</span>;
  //       },
  //     },
  //     {
  //       accessorKey: "name",
  //       header: "Name",
  //     },

  //     {
  //       accessorKey: "companyName",
  //       header: "Company Name",
  //       // cell: ({ getValue }) => <TruncatedCell value={getValue()} />,
  //     },
  //     {
  //       accessorKey: "mobileNumber",
  //       header: "Mobile No.",
  //     },
  //     {
  //       accessorKey: "email",
  //       header: "Email",
  //     },
  //     {
  //       accessorKey: "state",
  //       header: "State",
  //     },
  //     {
  //       accessorKey: "projectName",
  //       header: "ProjectName",
  //     },
  //     {
  //       accessorKey: "startDate",
  //       cell: ({ row }) => {
  //         // const dateTime = row.getValue("startDate");
  //         // return moment(dateTime).format("DD-MM-YYYY");
  //       },
  //       header: "Start Date",
  //     },
  //     {
  //       accessorKey: "endDate",
  //       cell: ({ row }) => {
  //         // const dateTime = row.getValue("endDate");
  //         // return moment(dateTime).format("DD-MM-YYYY");
  //       },
  //       header: "End Date",
  //     },
  //     {
  //       accessorKey: "amount",
  //       header: "Amount",
  //     },
  //     {
  //       accessorKey: "amountDue",
  //       header: "Amount Due",
  //     },
  //       {
  //       // accessorKey: "comments",
  //       cell: ({ row }) => {
  //         // return <div onClick={()=>navigate('/addproject',{projectId:row?.orginal._id})}>
  //         //  </div>
  //       },
  //       header: " Add Project",
  //     },
  //     // {
  //     //   // accessorKey: "comments",
  //     //   cell: ({ row }) => {
  //     //     return <AddCommentModal  id={row.original._id} />;
  //     //   },
  //     //   header: " Add Comments",
  //     // },

  //     // {
  //     //   // accessorKey: "comments",
  //     //   cell: ({ row }) => {
  //     //     return <CommentDialog data={row.original} />;
  //     //   },
  //     //   header: "Comments",
  //     // },

  //     {
  //       header: "Edit",
  //       cell: ({ row }) => {
  //         return (
  //           <div className="">
  //             <EditClientDialog
  //               // trigger={<button>Edit</button>}
  //               clients={row.original}
  //             />
  //           </div>
  //         );
  //       },
  //     },
  //   ];
  const JoinMeetingClick = (id) => {
    const data = {
      clientName: id.original.companyName,
      projectId: id.original.project[0]?._id,
      name: id.original.companyName,
      projectName: id.original.project[0]?.projectName,
      startDate: moment(id.original.startDate).format("YYYY-MM-DD"),
      endDate: moment(id.original.endDate).format("YYYY-MM-DD"),
      modules: id.original.project[0]?.projectModules,
    };
    if (id.original.added) {
      navigate(`/editProject/${id.original.project[0]._id}`);
    } else {
      navigate(`/addProject`, {
        state: data,
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "S.No",
        cell: ({ row }) => {
          return <span>{row.index + 1}</span>;
        },
      },

      {
        accessorKey: "name",
        header: "Name",
      },

      {
        accessorKey: "companyName",
        header: "Company Name",
        cell: ({ getValue }) => <TruncatedCell value={getValue()} />,
      },

      {
        accessorKey: "mobileNumber",
        header: "Mobile No.",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "state",
        header: "State",
      },
      {
        accessorKey: "projectName",
        header: "ProjectName",
      },

      {
        accessorKey: "startDate",
        cell: ({ row }) => {
          const dateTime = row.getValue("startDate");
          return moment(dateTime).format("DD-MM-YYYY");
        },
        header: "Start Date",
      },
      {
        accessorKey: "endDate",
        cell: ({ row }) => {
          const dateTime = row.getValue("endDate");
          return moment(dateTime).format("DD-MM-YYYY");
        },
        header: "End Date",
      },
      {
        header: "View",
        cell: ({ row }) => (
          <MoreDetails
            data={row?.original}
            modules={row?.original?.project[0]?.projectModules}
            trigger={<Eye size={20} color={"lightgrey"} />}
          />
        ),
      },
      // {
      //   accessorKey: "amount",
      //   header: "Amount",
      // },
      {
        accessorKey: "amountDue",
        header: "Amount Due",
      },
      {
        // accessorKey: "comments",
        cell: ({ row }) => {
          return (
            <button
              className="button-17 !rounded-[10px]"
              onClick={() => JoinMeetingClick(row)}
            >
              {row.original?.added ? "Edit project" : "Add Project"}
            </button>
          );
        },
        header: " Add Project",
      },
      {
        header: "Edit",
        cell: ({ row }) => {
          return (
            <div className="">
              <EditClientDialog
                // trigger={<button>Edit</button>}
                clients={row.original}
              />
            </div>
          );
        },
      },
    ],
    [data]
  );

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="grid gap-3">
      <div className="flex justify-end">
        <AddModal />
      </div>
      <div className="w-full  overflow-auto">
        <DataTable
          TableHeight={"400px"}
          columns={columns}
          data={data || []}
          heading="Clients"
          defaultTheme={true}
        />
      </div>
    </div>
  );
};

export default Client;
// import axios from "";



