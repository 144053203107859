import TextField from "@mui/material/TextField";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import useLeadsHooks from "./Leads.hook";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "../../components/ui/dialog";

import { Button } from "../../components/ui/button.jsx";
import { AddIcon } from "../../components/ui/icons.jsx";

const AddLeadModal = () => {
  const { control, handleSubmit, setValue, reset, formState } = useForm();

  const { errors } = formState;
  const { addLeadMutation } = useLeadsHooks();

  const statusOptions = ["Followup", "Close", "Hot Leads", "Done"];

  const onSubmit = (data) => {
    addLeadMutation.mutate(data);
    reset();
  };

  const handlePhoneChange = (e, field) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, "");
    const slicedValue = numericValue.slice(0, 10);
    field.onChange(slicedValue); // Update the field value properly
  };

  return (
    <Dialog>
      <DialogTrigger className="flex text-sm items-center gap-2">
        <span> Add Leads</span>
        <AddIcon />
      </DialogTrigger>
      <DialogContent>
        <div>
          <h2 id="modal-title" className="mb-4 ml-3">
            Lead Form
          </h2>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              margin: "12px",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div style={{ display: "flex", gap: "16px" }}>
              <Controller
                name="clientName"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: "Client Name is required",
                  },

                  pattern: {
                    value: /^[a-zA-Z ]*$/,
                    message: "Only Alphabhet are allowed",
                  },

                  maxLength: {
                    value: 50,
                    message: "Maximum 50 characters are allowed",
                  },

                  minLength: {
                    value: 3,
                    message: "Minimum 3 characters are allowed",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Client Name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.clientName}
                    helperText={errors.clientName?.message}
                  />
                )}
              />
              <Controller
                name="companyName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Company Name is required",
                  },

                  validate: (value) =>
                    value.trim() !== "" || "Company Name is required",

                  pattern: {
                    value: /^[a-zA-Z0-9\s]*$/,
                    message: "Only alphabet or number are allowed",
                  },

                  maxLength: {
                    value: 50,
                    message: "Maximum 50 characters are allowed",
                  },
                }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Company Name"
                    variant="outlined"
                    fullWidth
                    error={!!errors.companyName}
                    helperText={errors.companyName?.message}
                  />
                )}
              />
            </div>
            <div style={{ display: "flex", gap: "16px" }}>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: "Phone is required",
                  },

                  pattern: {
                    value: /^(\+91[\-\s]?)?[0]?(91)?[6789]\d{9}$/,
                    message: "Add Valid Number",
                  },

                  maxLength: {
                    value: 10,
                    message: "Maximum 10 digits are allowed",
                  },

                  minLength: {
                    value: 10,
                    message: "Minimum 10 digits are allowed",
                  },
                }}
                render={({ field }) => (
                  <>
                    <TextField
                      {...field}
                      label="Phone"
                      variant="outlined"
                      fullWidth
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                      onChange={(e) => handlePhoneChange(e, field)} 
                    />
                  </>
                )}
              />
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",

                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Please enter a valid email",
                  },

                  minLength: {
                    value: 3,
                    message: "Minimum 3 characters are allowed",
                  },
                  maxLength: {
                    value: 50,
                    message: "Maximum 50 characters are allowed",
                  },
                }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </div>

            <div style={{ display: "flex", gap: "16px" }}>
              <Controller
                name="enquiryAbout"
                control={control}
                rules={{
                  // required: "Enquiry About is required",
                  pattern: {
                    value: /^[A-Za-z\s]+$/i,
                    message: "Enquiry About should contain only alphabets",
                  },

                  maxLength: {
                    value: 50,
                    message: "Enquiry About should not exceed 50 characters",
                  },

                  minLength: {
                    value: 3,
                    message:
                      "Enquiry About should contain atleast 3 characters",
                  },

                  validate: (value) =>
                    value.trim() !== "" || "Enquiry About is required",
                }}
               defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Enquiry About"
                    variant="outlined"
                    fullWidth
                    error={!!errors.enquiryAbout}
                    helperText={errors.enquiryAbout?.message}
                  />
                )}
              />

              <Controller
                name="feedBack"
                rules={{
                  // required: "Feedback is required",
                  pattern: {
                    value: /^[A-Za-z\s]+$/i,
                    message: "Feedback should contain only alphabets",
                  },

                  maxLength: {
                    value: 100,
                    message: "Feedback should not exceed 100 characters",
                  },

                  minLength: {
                    value: 3,
                    message: "Feedback should contain atleast 3 characters",
                  },

                  validate: (value) =>
                    value.trim() !== "" || "Feedback is required",
                }}
                control={control}
                 defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Feedback"
                    variant="outlined"
                    fullWidth
                    error={!!errors.feedBack}
                    helperText={errors.feedBack?.message}


                  />
                )}
              />
            </div>
            <Controller
              name="status"
              control={control}
              defaultValue=""
              rules={{
                required: "Status is required",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  label="Status"
                  variant="outlined"
                  fullWidth
                  error={!!errors.status}
                  helperText={errors.status?.message}
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value=""  >
                   
                  </option>

                  {statusOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </TextField>
              )}
            />

            <Controller
              name="link"
              control={control}
              defaultValue=""
              rules={{
                required: "URL link is required",
                pattern: {
                  value:
                    /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
                  message: "Please enter a valid URL",
                },
                validate: {
                  checkProtocol: (value) => {
                    if (
                      value &&
                      !value.startsWith("http://") &&
                      !value.startsWith("https://")
                    ) {
                      return "URL must start with http:// or https://";
                    }
                    return true;
                  },
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="URL Link"
                  variant="outlined"
                  fullWidth
                  error={!!errors.link}
                  helperText={errors.link?.message}
                  SelectProps={{
                    native: true,
                  }}
                ></TextField>
              )}
            />
            <DialogFooter>
              {formState.isValid && (
                <DialogClose asChild>
                  <Button type="submit">Save</Button>
                </DialogClose>
              )}

              {!formState.isValid && <Button type="submit">Save</Button>}

              <DialogClose asChild>
                <Button onClick={() => reset()}>Close</Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddLeadModal;
