import React, { useEffect, useState } from 'react'
import { DataTable } from '../../components/table/Data-Table'
import axios from '../../config/axiosAuth'
import { baseUrl } from '../../config/baseUrl'
import { useLocation, useParams } from 'react-router-dom'
import moment from 'moment'

const EmployeLeaveList = () => {
    const [data,setData]=useState([])
    const[month,setMonth]=useState([])
    const { id } = useParams();
    useEffect(()=>{
        getEmployeeleaveHistory()
    },[id])
    const getEmployeeleaveHistory=async()=>{
        try{
    const  response=await axios.post(`${baseUrl}/employee-leaves`,{
    employeeId:id,
    month:''
})
setData(response.data.data)
        }catch(err){

        }
    }
    const Columns=[
        {
            header:'S.No',
            cell:({row})=>row.index+1
        },
        {
            header:'subject',
            cell:({row})=>row.original?.subject
        },  {
            header:'Leave From',
            cell:({row})=>moment(row.original?.fromDate).format('DD-MM-YYYY')
        },  {
            header:'Leave To',
            cell:({row})=>moment(row.original?.toDate).format('DD-MM-YYYY')
        }, 
        {
            header:'Duration',
            cell:({row})=>row.original?.leaveType
        },  {
            header:'Leave Status',
            cell:({row})=><span style={{color:row.original?.status==='pending'?'orange':row.original?.status==='approved'?'green':row.original?.status==="rejected"?'red':''}}>{row.original?.status}</span>
        },
    ]
  return (
   <DataTable columns={Columns} data={data||[]} defaultTheme={true} TableHeight={'400px'} heading={'Detailed View'}/>
  )
}

export default EmployeLeaveList
