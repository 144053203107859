import React, { useState } from "react";
import Modal from "react-modal";
import { customStyles } from "../infoCard/EmpForTheMonth";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import useEmployeeStore from "../../store/employeeStore";
import { Button } from "../ui/button";

export const EmployeeOfMonthModal = ({ isOpen, onClose }) => {
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");

  const { addEmployeeOfMonthMutation } = useEmployeeHook();

  const { employees } = useEmployeeStore();

  const handleAddEmployee = () => {
    if (!selectedEmployeeId) {
      return;
    }

    const employeeId = selectedEmployeeId;

    addEmployeeOfMonthMutation.mutate({ employeeId });
    

    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      cozzntentLabel="Employee of the Month Modal"
    >
      <h2>Add Employee of the Month</h2>
      <br></br>

      <div>
        <label>Select Employee:</label>

        <select
          value={selectedEmployeeId}
          onChange={(e) => setSelectedEmployeeId(e.target.value)}
        >
          <option value="">Select an employee</option>
          {employees?.map((employee) => (
            <option key={employee._id} value={employee._id}>
              {employee.name}
            </option>
          ))}
        </select>
      </div>
      <br></br>
      <Button className="btn btn-primary" onClick={handleAddEmployee}>
      Submit
      </Button>
    </Modal>
  );
};
