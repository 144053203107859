import React, { useMemo } from "react";
import { DataTable } from "../../components/table/Data-Table";
import { ResendCredentials } from "../team/columns";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import moment from "moment";

const Mail = () => {
  const { credRequests } = useEmployeeHook({credRequests:true});
  const columns = useMemo(
    () => [
      {
        header: "S.No",
        cell: ({ row }) => {
          return <span>{row.index + 1}</span>;
        },
      },

      {
        cell: ({ row }) => {
          return <span>{row.original?.employeeId?.name}</span>;
        },
        header: "Name",
      },
      {
        cell: ({ row }) => {
          return <span>{row.original?.employeeId?.email}</span>;
        },
        header: "Email",
      },

      {
        cell: ({ row }) => {
          return <span>{row.original?.employeeId?.employeeId}</span>;
        },
        header: "Employee Id",
      },
      {
        accessorKey: "designation",
        cell: ({ row }) => row.original?.employeeId?.designation?.name,
      },
      {
        cell: ({ row }) => row.original?.employeeId?.role,
        header: "Role",
      },

      {
        // accessorKey: "password",
        cell: ({ row }) =>
          moment(row?.original?.createdAt).format("DD-MM-yyyy"),
        header: "Request sent on",
      },

      // {
      //   // accessorKey: "password",
      //   cell: ({ row }) => <Password password={row?.original?.password} />,
      //   header: "Password",
      // },
      {
        // accessorKey: "password",
        cell: ({ row }) => <ResendCredentials employeeId={row.original?.employeeId?._id} />,
        header: "Send Credentials",
      },
    ],
    [credRequests]
  );
  return (
    <div>
      <DataTable
        heading={"Resend-credential Requests"}
        data={credRequests || []}
        columns={columns}
        defaultTheme={true}
      />
    </div>
  );
};

export default Mail;
