// import { Delete, Trash } from "lucide-react";
// import moment from "moment";
// import React, { useEffect, useState } from "react";
// import { Controller, useFieldArray } from "react-hook-form";
// import ReactSelect from "react-select";
// import { SelectCustomStyles } from "../../../utils/styles";
// import { IoIosRemoveCircle } from "react-icons/io";
// import useEmployeeHook from "../../hooks/useEmployeeHook";
// import { baseUrl } from "../../config/baseUrl";
// import axios from "../../config/axiosAuth";

// export  const NestedFields =({
//   nestIndex,
//   control,
 
//   register,
//   employeeOptions,
//   modules,
//   watch,
//   setValue,
//   removeTeam
// }) => {
//   const { fields, remove, append } = useFieldArray({
//     control,
//     name: `allocations.${nestIndex}.teamType`,
//   });
//   const { departmentList } = useEmployeeHook();

//   const [teamMembers, setTeamMebers] = useState([]);
//   const [departmentOptions, setDepartmentOptions] = useState([]);

  
//   const deleteTeam = async (teamIndex) => {
//     try {
//       remove(teamIndex)
//     } catch (err) {
//       throw new Error(err);
//     }
//   };
//    useEffect(()=>{
//     setTeamMebers(employeeOptions)
//   },[employeeOptions])
//   useEffect(()=>{
//     console.log(teamMembers,'dataaa teaaaaaaaaa',employeeOptions)

//   },[teamMembers,employeeOptions])
//   const getTeamMebers = async (teamId, index) => {
//     try {
//       const response = await axios.post(baseUrl + "/team-members", {
//         id: teamId,
//       });
//       console.log(response.data.result.teamMembers, "response of team members");
//       setTeamMebers((prev) => ({
//         ...prev,
//         [index]: response.data?.result?.teamMembers?.map((item) => ({
//           value: item._id,
//           label: item.name,
//         })),
//       }));
//     } catch (err) {
//       throw new Error(err);
//     }
//   };
//   useEffect(() => {
//     if (departmentList) {
//       setDepartmentOptions(
//         departmentList?.map((item) => ({
//           value: item._id,
//           label: item.teamName,
//         }))
//       );
//     }
//   }, [departmentList]);
//   return (
//     <div>
//       {fields.map((item, k) => {
//         return (
//           <div key={item.id} style={{ marginLeft: 20 }} className="form-row">
//             {/* <label>Add Employee </label> */}
//             <div className="form-group">
//                   <Controller
//                     // name={`allocations.${index}.teamName`}
//                     name={`allocations.${nestIndex}.teamType.${k}.teamName`}
//                     control={control}
//                     render={({ field }) => {
//                       const selectedOption = departmentList?.find(
//                         (dept) => dept._id === field.value
//                       );

//                       return (
//                         <ReactSelect
//                           {...field}
//                           styles={SelectCustomStyles}
//                           isClearable
//                           value={
//                             selectedOption
//                               ? {
//                                   value: selectedOption._id,
//                                   label: selectedOption.teamName,
//                                 }
//                               : null
//                           }
//                           onChange={(option) => {
//                             return (
//                               field.onChange(option ? option.value : null),
//                               getTeamMebers(option?.value, k)
//                             );
//                           }}
//                           options={departmentOptions}
//                           placeholder={"Select a team ...."}
//                         />
//                       );
//                     }}
//                   />
//                 </div>

//                 <div className="form-group">
//                   <Controller
//                     name={`allocations.${nestIndex}.teamType.${k}.teamMembers`} // Ensure this path matches your form data structure
//                     control={control}
//                     render={({ field }) => {
//                       return (
//                         <ReactSelect
//                           {...field}
//                           isMulti
//                           styles={SelectCustomStyles}
//                           options={Array.isArray(teamMembers?.[k]) ? teamMembers[k] : []}
//                           onChange={(selectedOptions) => {
//                             const selectedValues = selectedOptions
//                               ? selectedOptions.map((option) => option.value)
//                               : [];
//                             field.onChange(selectedValues);
//                           }}
//                           value={teamMembers?.[k]||[]?.filter(
//                             (option) =>
//                               field.value && field.value.includes(option.value)
//                           )}
//                           isClearable
//                         />
//                       );
//                     }}
//                   />
//                 </div>
//             {/* <div className="form-row">
//               <Controller
//                 render={({ field }) => (
//                   <ReactSelect
//                     styles={SelectCustomStyles}
//                     isClearable
//                     {...field}
//                     options={employeeOptions}
//                     placeholder={"Select a Employee ...."}
//                     onChange={(val) => field.onChange(val ? val.value : null)}
//                     value={
//                       employeeOptions?.find(
//                         (option) => option.value === field.value
//                       ) || null
//                     }
//                   />
//                 )}
               
//                 control={control}
//               />
//             </div> */}
//             {/* <div className="form-group">
//               <Controller
//                 render={({ field }) => (
//                   <ReactSelect
//                     styles={SelectCustomStyles}
//                     isMulti
//                     isClearable
//                     options={modules}
//                     placeholder={"Select modules ...."}
//                     onChange={(selectedOptions) => {
//                       const selectedValues = selectedOptions
//                         ? selectedOptions.map((option) => option.value)
//                         : [];
//                       field.onChange(selectedValues);
//                     }}
//                     value={modules.filter(
//                       (option) =>
//                         field.value && field.value.includes(option.value)
//                     )}
//                   />
//                 )}
//                 name={`teams.${nestIndex}.members.${k}.modules`}
//                 control={control}
//               />
//             </div> */}
          

//             <button type="button" onClick={() => remove(k)}>
//               <IoIosRemoveCircle color={"red"} />
//             </button>
//           </div>
//         );
//       })}

// <div className="flex gap-4 mb-[10px]">

// <button type="button" onClick={() => append({})} className="flex items-center justify-center gap-2">
//   <div className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff]" style={{backgroundColor:'rgb(8,83,148,0.3)'}}>+</div> Team Allocation
// </button>
// <button type="button" onClick={() => removeTeam(nestIndex)}  className="flex items-center justify-center gap-2">
// <div className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff] text-[14px]" style={{backgroundColor:' rgb(255,0,0,0.4)'}} onClick={() => removeTeam(nestIndex)}>-</div> Employee
//         </button>
// </div>

//       <hr />
//     </div>
//   );
// };
// const style = {
//   control: (baseStyles, state) => ({
//     ...baseStyles,

//     height: "40px",
//     border: " 1px solid #ccc",
//     borderRadius: "5px",
//   }),
// };
import { Delete, Trash } from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import ReactSelect from "react-select";
import { SelectCustomStyles } from "../../../utils/styles";
import { IoIosRemoveCircle } from "react-icons/io";
import useEmployeeHook from "../../hooks/useEmployeeHook";
import { baseUrl } from "../../config/baseUrl";
import axios from "../../config/axiosAuth";
import CommonAlert from "../../components/ui/CommonAlert";
import { cn } from "../../../utils/lib";

export  const NestedFields =({
  nestIndex,
  control,
 
  register,
  employeeOptions,
  modules,
  watch,
  setValue,
  showButtons,
  removeTeam
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `allocations.${nestIndex}.teamType`,
  });
  const { departmentList } = useEmployeeHook({departmentList:true});

  const [teamMembers, setTeamMebers] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  
  const deleteTeam = async (teamIndex) => {
    try {
      remove(teamIndex)
    } catch (err) {
      throw new Error(err);
    }
  };
  useEffect(()=>{
    setTeamMebers(employeeOptions)
  },[employeeOptions])
  const getTeamMebers = async (teamId, index) => {
    try {
      const response = await axios.post(baseUrl + "/team-members", {
        id: teamId,
      });
      setTeamMebers((prev) => ({
        ...prev,
        [index]: response.data?.result?.teamMembers?.map((item) => ({
          value: item._id,
          label: item.name,
        })),
      }));
    } catch (err) {
      throw new Error(err);
    }
  };
  useEffect(() => {
    if (departmentList) {
      setDepartmentOptions(
        departmentList?.map((item) => ({
          value: item._id,
          label: item.teamName,
        }))
      );
    }
  }, [departmentList]);
  return (
    <div>
      {fields.map((item, k) => {
        return (
          <div key={item.id} style={{ marginLeft: 20 }} className="form-row">
            <div className="form-group">
                  <Controller
                    name={`allocations.${nestIndex}.teamType.${k}.teamName`}
                    control={control}
                    render={({ field }) => {
                      const selectedOption = departmentList?.find(
                        (dept) => dept._id === field.value
                      );

                      return (
                        <ReactSelect
                        className={cn({"pointer-events-none":!showButtons})}
                          {...field}
                          styles={SelectCustomStyles}
                          isClearable
                          value={
                            selectedOption
                              ? {
                                  value: selectedOption._id,
                                  label: selectedOption.teamName,
                                }
                              : null
                          }
                          onChange={(option) => {
                            return (
                              field.onChange(option ? option.value : null),
                              getTeamMebers(option?.value, k)
                            );
                          }}
                          options={departmentOptions}
                          placeholder={"Select a team ...."}
                        />
                      );
                    }}
                  />
                </div>

                <div className="form-group">
                  <Controller
                    name={`allocations.${nestIndex}.teamType.${k}.teamMembers`} // Ensure this path matches your form data structure
                    control={control}
                    render={({ field }) => {
                      return (
                        <ReactSelect
                        className={cn({"pointer-events-none":!showButtons})}

                          {...field}
                          isMulti
                          styles={SelectCustomStyles}
                          options={teamMembers?.[k]}
                          onChange={(selectedOptions) => {
                            const selectedValues = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];
                            field.onChange(selectedValues);
                          }}
                          value={(teamMembers?.[k]||[])?.filter(
                            (option) =>
                              field.value && field.value.includes(option.value)
                          )}
                          isClearable
                        />
                      );
                    }}
                  />
                </div>
                   
          
               { showButtons&&
             <CommonAlert
             width={'100px'}
             trigger={<div type="button" >
             <IoIosRemoveCircle color={"red"} />
           </div>}
             onClick={() => remove(k)}
             heading={"Remove"}
             text={"Are you sure you want to Remove ?"}
           />  
            }
          </div>
        );
      })}

{showButtons&&<div className="flex gap-4 mb-[10px]">

<button type="button" onClick={() => append({})} className="flex items-center justify-center gap-2">
  <div className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff]" style={{backgroundColor:'rgb(8,83,148,0.3)'}}>+</div> Team Allocation
</button>

<CommonAlert
width={'100px'}
            trigger={<div type="button"  className="flex items-center justify-center gap-2">
            <div className="w-[20px] h-[20px] bg-rgb(8 83 148,0.4) flex items-center justify-center rounded-[50%] text-[#eaf2ff] text-[14px]" style={{backgroundColor:' rgb(255,0,0,0.4)'}} onClick={() => removeTeam(nestIndex)}>-</div> Employee
                    </div> }
            onClick={() => removeTeam(nestIndex)}
            heading={"Remove"}
            text={"Are you sure you want to Remove ?"}
          />

</div>}

      <hr />
    </div>
  );
};
const style = {
  control: (baseStyles, state) => ({
    ...baseStyles,

    height: "40px",
    border: " 1px solid #ccc",
    borderRadius: "5px",
  }),
};
