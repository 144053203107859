import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import { base } from "../../config/baseUrl";
import io from "socket.io-client";
import { useQuery } from "react-query";
import authService from "../../service/authServices";
import useAdminHook from "../../hooks/adminHook";
import "./meetings.css";
import {
  enabledMic,
  enabledVideo,
  disableMic,
  disableVideo,
  endcallButton,
  enterFullScreen,
  enableSpeaker,
} from "./importConstants";
import { LuScreenShare } from "react-icons/lu";
import { IoIosChatboxes } from "react-icons/io";
import { useLocation, useOutletContext } from "react-router-dom";
import RightSection from "./RightSection";

const Meeting = () => {
  // const Room = "ABC1234567";
  const { state } = useLocation();
  const [socketRef, zegoRef] = useOutletContext();
  const uniqueId = state.id;
  const Room = state.linkId;
  console.log(state, "state from navigate ");
  const peerRef = useRef(null);

  const localStreamId = useRef(null);
  const remoteStreamId = useRef(null);
  const localVideoStream = useRef(null);
  const remoteVideoStream = useRef([]);
  const roomId = useRef(null);
  const myUserId = useRef(null);
  const screenStreamId = useRef(null);
  const screenMixingTaskId = useRef(null);
  const myUserName=useRef(null)
  //////////////
  const [MicroPhoneMuted, setMicroPhoneMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [speakerMuted, setSpeakerMuted] = useState(false);
  const [callStarted, setCallStarted] = useState(false);
  const [screenShareStream, setScreenShareStream] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [remoteVideoList, setRemoteVideoList] = useState([]);
  const [removedVideoList, setRemovedVideoList] = useState([]);
  const [peerPresent, setPeerPresent] = useState(false);
  const [showChatSection, setShowChatSection] = useState(false);
  const [refsSet, setRefsSet] = useState(false);
  const { adminProfile: Admin } = useAdminHook();
  const [acceptOrRejectPopup, setAcceptOrRejectPopup] = useState(false);
  const [requestList, setRequestList] = useState([]);
  const [unFlipLocalVideo, setUnFlipLocalVideo] = useState(false);
  const [screenShareStreamObject, setScreenShareStreamObject] = useState(null);
  const [messages, setMessages] = useState([]);
  useLayoutEffect(() => {
    setTimeout(() => {
      socketRef.current.on("joined", (data) => {
        console.log("joined",data);
        const DATA = JSON.stringify(data);
        const currentRoomId = JSON.parse(DATA);
        roomId.current = currentRoomId.roomId;
        myUserId.current = currentRoomId.userId;
        myUserName.current=currentRoomId.userName;

        // console.log(
        //   `userJoinedin ${currentRoomId.roomId} user ${currentRoomId.userId}`
        // );
        startCall();
      });
      socketRef.current.on("requestedUsers", (data) => {
        setRequestList(data.users);
        console.log(data.users, "userRequests");
      });
      socketRef.current.on("allAcceptedUsers");
      socketRef.current.on("joinRequest", (data) => {
        console.log(data, "joinRequest");
        setRequestList(data.requestingUser);
      });
      socketRef.current.on("message", (data) => {
        setMessages((prev) => [...prev, data.message]);
        console.log("recieved ", data);
      });
      socketRef.current.on("ready", (data) => {
        console.log(data, "peerpresent");
        setPeerPresent(true);
      });
      socketRef.current.on("replacedStream", async (data) => {
        setScreenShareStream(data.streamId);
        await zegoRef.current.stopPlayingStream(data.streamId);
        const screenStream = await zegoRef.current.startPlayingStream(
          data.streamId
        );
        setScreenShareStreamObject(screenStream);
        // console.log("replacedStream ran",data, data.streamId);
      });
      socketRef.current.on("resetReplacedStream", (data) => {
        setScreenShareStream(null);
        setScreenShareStreamObject(null);
        // console.log("resetReplacedStream ran",data);
      });
      socketRef.current.on("finalStreams", (data) => {
        const updatedStreams = data.streamIds.filter((item) => {
          return item.streamId !== localStreamId.current;
        });
        console.log("finalStreams Ran with  this data", data);

        setRemoteVideoList(updatedStreams);
      });
      socketRef.current.on("flippedStreams", async (data) => {
        if (data.flippedStreamIds.length > 0) {
          setScreenShareStream(data.flippedStreamIds[0]);
          console.log("flippedStreams ran", data);
          // console.log("replacedstream", "data.streamId=", data.streamId);
          await zegoRef.current.stopPlayingStream(data.flippedStreamIds[0]);
          const screenStream = await zegoRef.current.startPlayingStream(
            data.flippedStreamIds[0]
          );
          setScreenShareStreamObject(screenStream);
          setRefsSet(true);
        }
        console.log("flippedStreams ran", data);
      });
    }, 1000);
    return () => {
      console.log("Disconnecting from server");
      endVideoCall();
    };
  }, []);

  const GetToken = async () => {
    const res = await authService.zegoToken();
    return res;
  };

  const {
    data: zegoToken,
    isLoading,
    isError,
  } = useQuery("zegoToken", GetToken, {
    onSuccess: (data) => {
      localStorage.setItem("zegoToken", data.token);
    },
  });

  const PlayRemoteStreams = useCallback(async (streamID, videoElement) => {
    if (streamID !== null && videoElement) {
      console.log("entered play stream");
      await zegoRef.current.stopPlayingStream(streamID);
      const remoteStream = await zegoRef.current.startPlayingStream(streamID);
      videoElement.srcObject = remoteStream;
    }
    // }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (refsSet) {
        console.log("refsAfterDelay");
        remoteVideoList?.forEach((stream, index) => {
          PlayRemoteStreams(stream.streamId, remoteVideoStream.current[index]);
        });
      }
    }, 1000);
  }, [
    refsSet,
    PlayRemoteStreams,
    remoteVideoList,
    screenShareStream,
    screenShareStreamObject,
  ]);

  useEffect(() => {
    const updatedArray = remoteVideoList.filter(
      (item) => !removedVideoList.includes(item)
    );
    // console.log(remoteVideoList, "rremotevideooooo");
  }, [removedVideoList]);

  const startCall = async () => {
    zegoRef.current.setDebugVerbose(false);
    zegoRef.current.on(
      "roomStateChanged",
      async (roomID, reason, errorCode, extendedData) => {
        if (reason == "LOGINED") {
          console.log(
            "Connected to the room successfully. You can perform operations such as stream publishing and playing only after the room is successfully connected.",
            roomID,
            reason,
            errorCode,
            extendedData
          );
        } else if (reason == "LOGIN_FAILED") {
          console.log("failed to login ");
        }
      }
    );
    zegoRef.current.on("roomUserUpdate", (roomId, updateType, userList) => {
      console.log(roomId, updateType, userList, "userlist");
      setUsersList((prev) => [...prev, userList.id]);
      console.log(usersList, "usersList");
    });
    zegoRef.current.on(
      "roomStreamUpdate",
      async (roomId, updateType, streamList, extendedData) => {
        console.log(streamList[0].streamID,'streamList from admin',streamList)
        if (updateType == "ADD") {
          console.log(typeof streamList, "streamList");

          console.log();
        } else if ((updateType = "DELETE")) {
          console.log(streamList[0].streamID,'streamList from admin in the delete',streamList)

          remoteVideoList.length <= 0 ? setRefsSet(false) : setRefsSet(true);
          socketRef.current.emit("removeStreamFromList", {
            streamId: streamList[0].streamID,
            room: Room,
          });
        }
      }
    );

    const configOption = {
      videoBitrate: 300,
      audioBitrate: 48,
      camera: {
        audio: true,
        video: {
          quality: 4,
          width: 480,
          height: 480,
          frameRate: 15,
        },
      },
    };
    const token = localStorage.getItem("zegoToken");
    try {
      const loginResponse = await zegoRef.current.loginRoom(
        // roomId.current,
        Room,
        token,
        { userID: myUserId.current, userName: Admin?.name },
        { userUpdate: true },
        { isUserStatusNotify: true }
      );
      if (loginResponse == true) {
        console.log("loginned hurrey");
        try {
          localVideoStream.current = await zegoRef.current.createZegoStream({
            configOption,
          });
          const localStream = localVideoStream.current;
          console.log(localStream, "localStream");
          localVideoStream.current.audioMuted = false;
          localStream.playVideo(document.querySelector("#local-video"), {
            enableAutoPlayDialog: true,
          });
          localStreamId.current = new Date().getTime().toString();
          zegoRef.current.startPublishingStream(
            localStreamId.current,
            localVideoStream.current,
            { roomID: Room }
          );
          console.log(localStreamId.current, "adminStreamId");
          socketRef.current.emit("addStreamToList", {
            streamId: localStreamId.current,
            room: Room,
            userId: myUserId.current,
          });
        } catch (error) {
          console.error(
            "Failed to create stream or permissions are off",
            error
          );

          if (
            error.name === "NotAllowedError" ||
            error.name === "PermissionDeniedError"
          ) {
            alert(
              "Please grant permission to access your camera and microphone."
            );
          } else {
            alert(
              "An error occurred while creating the stream. Please enable Audio  and video permissions and try again."
            );
            //   endVideoCall();
          }
        }
      } else if (loginResponse !== true) {
        console.error("Failed to login to the room");
        alert(
          "Failed to login to the room. Please check your credentials and try again."
        );
      }

      zegoRef.current.on("publisherStateUpdate", (result) => {
        var state = result["state"];
        var streamID = result["streamID"];
        var errorCode = result["errorCode"];
        var extendedData = result["extendedData"];
        if (state == "PUBLISHING") {
          console.log(
            "Successfully published an audio and video stream:",
            streamID
          );
        } else if (state == "NO_PUBLISH") {
          console.log("No audio and video stream published");
        } else if (state == "PUBLISH_REQUESTING") {
          console.log(
            "Requesting to publish an audio and video stream:",
            streamID
          );
        }
        console.log("Error code:", errorCode, " Extra info:", extendedData);
        console.log(errorCode, "huhuuuuuhu");
        if (errorCode !== 0) {
          console.log("please refresh your page and retry ");
        }
      });
      zegoRef.current.on("roomUserUpdate", (roomId, updateType, userList) => {
        console.log(roomId, updateType, userList);
        if (updateType === "DELETE") {
          // endVideoCall();
          console.log("user Left");
        } else if (updateType === "ADD") {
          console.log("userJoined");
        }
      });

      const config = {
        logLevel: "disable",
        remoteLogLevel: "disable",
        logURL: "disable",
      };
      zegoRef.current.setLogConfig(config);
    } catch (err) {
      endVideoCall();
      console.log(err, "login error huhuhu");
    }
    try {
      zegoRef.current.on(
        "roomStateUpdate",
        (roomId, state, errorCode, extendedData) => {
          console.log(
            roomId,
            state,
            errorCode,
            extendedData,
            "roomStateUpdate"
          );
          if (errorCode === 1000017) {
          }
          console.log(errorCode, extendedData, "heheheh");
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  const HandleClick = () => {
    setRemoteVideoList([]);

    socketRef.current.emit("adminJoin", { room: Room });

    // socketRef.current.emit("join");
    setCallStarted(true);
  };
  const stateCleanups = () => {
    setMicroPhoneMuted(false);
    setVideoMuted(false);
    setShowChatSection(false);
    setSpeakerMuted(false);
    setCallStarted(false);
    setScreenShareStream(null);
    setUsersList([]);
    setRemoteVideoList([]);
    setPeerPresent(false);
    setRefsSet(false);
    setUnFlipLocalVideo(false);
    setScreenShareStreamObject(null);
    setMessages([])
  };
  const endVideoCall = () => {
    setRemoteVideoList([]);
    setRefsSet(false);
    stateCleanups();
    try {
      zegoRef.current?.stopPublishingStream(localStreamId.current);
      zegoRef.current?.destroyStream(localVideoStream.current);
    } catch (error) {
      console.error("Error stopping local video stream:", error);
    }
    socketRef.current.emit("removeStreamFromList", {
      streamId: localStreamId.current,
      room: Room,
    });
    localVideoStream.current = null;
    zegoRef.current?.logoutRoom(Room);

    // zegoRef.current?.destroyEngine();
    // zegoRef.current = null;
    setCallStarted(false);
    socketRef.current.emit("removeUserFromRoom", {
      userId: myUserId.current,
      room: Room,
    });
  };
  const muteUser = (user) => {
    socketRef.current.emit("muteUser", { userId: user });
  };
  const ToggleVideo = () => {
    console.log("localVideoRef.current:", localVideoStream.current);
    setVideoMuted(!videoMuted);
    const stream = localVideoStream.current;
    zegoRef.current.mutePublishStreamVideo(stream, !videoMuted);
  };

  const toggleAudio = () => {
    setMicroPhoneMuted(!MicroPhoneMuted);
    zegoRef.current.muteMicrophone(!MicroPhoneMuted);
  };
  const startScreenShare = async () => {
    setSpeakerMuted(true);
    try {
      await localVideoStream.current.startCaptureScreen();
      await zegoRef.current.updatePublishingStream(localVideoStream.current, 0);
      // setUnFlipLocalVideo(true);
      socketRef.current.emit("replaceOriginalStreamWithScreenshareStream", {
        streamId: localStreamId.current,
        room: Room,
      });
      socketRef.current.emit("addStreamToList", {
        streamId: localStreamId.current,
        room: Room,
        userId: myUserId.current,
      });
    } catch (err) {
      console.log(err);
      setUnFlipLocalVideo(false);
    }
  };

  const stopScreenSharing = async () => {
    setSpeakerMuted(false);
    socketRef.current.emit("replaceScreenshareStreamWithOriginalStream", {
      streamId: localStreamId.current,
      userId: myUserId.current,
      room: Room,
    });
    setUnFlipLocalVideo(false);
    setScreenShareStream(null);
    reUpdateStream();
  };
  useEffect(() => {
    setTimeout(() => {
      zegoRef.current.on("screenSharingEnded", (stream) => {
        setSpeakerMuted(false);
        socketRef.current.emit("replaceScreenshareStreamWithOriginalStream", {
          streamId: localStreamId.current,
          room: Room,
          userId: myUserId.current,
        });
        setUnFlipLocalVideo(false);
        setScreenShareStream(null);
        reUpdateStream();
      });
    }, 1000);
  }, []);
  const reUpdateStream = async () => {
    try {
      await localVideoStream.current.startCaptureCamera();
      await zegoRef.current.updatePublishingStream(localVideoStream.current, 0);
    } catch (er) {}
  };
  useEffect(() => {
    if (unFlipLocalVideo) {
      const newLocalVideo = document.querySelector(
        ".screenshare_view #local-video"
      );
      if (newLocalVideo && localVideoStream.current) {
        localVideoStream.current.playVideo(newLocalVideo, {
          enableAutoPlayDialog: true,
        });
      }
    } else {
      const newLocalVideo = document.querySelector(" #local-video");
      if (newLocalVideo && localVideoStream.current) {
        localVideoStream.current.playVideo(newLocalVideo, {
          enableAutoPlayDialog: true,
        });
      }
    }
  }, [unFlipLocalVideo]);
  const chatIconClick = () => {
    console.log("clicked chatsection", showChatSection);
    setShowChatSection(!showChatSection);
  };

  const admitUserToRoom = (id) => {
    socketRef.current.emit("acceptJoinRequest", {
      requestingUser: id,
      room: Room,
    });
    console.log("clicked", id);
    const updatedList = requestList.filter((item) => item.id !== id);
    console.log(requestList, updatedList, id);
    setRequestList(updatedList);
  };
  console.log(screenShareStream, "screensharestream from admin ");
  const rejectUserRequestToJoinRoom = (id) => {
    socketRef.current.emit("rejectJoinRequest", {
      requestingUser: id,
      room: Room,
    });
    console.log("clicked", id);
    const updatedList = requestList.filter((item) => item !== id);
    console.log(requestList, updatedList, id);
    setRequestList(updatedList);
  };

  const kickUserFromRoom = (user) => {
    console.log("kicking user:", user);
    socketRef.current.emit("kickUser", { userId: user });
  };
  useEffect(() => {
    if (requestList && requestList.length > 0) {
      setAcceptOrRejectPopup(true);
    } else {
      setAcceptOrRejectPopup(false);
    }
  }, [requestList]);
  // console.log(remoteVideoList.length, "length remote");

  return (
    <div className="meeting_layout">
      {!callStarted && <div onClick={HandleClick}>JoinCall</div>}
      {callStarted && (
        <>
          <div
            style={{ position: "relative" }}
            className={
              showChatSection
                ? "remote_video_section_with_chat_box remote_video_section"
                : "remote_video_section"
            }
          >
            {screenShareStream !== null && (
              <div className="screenshare_view">
                {unFlipLocalVideo && (
                  <div
                    id="local-video"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
                {screenShareStream !== null &&
                  (
                  (
                    <video
                      autoPlay
                      playsInline
                      ref={(el) => {
                        if (el && screenShareStreamObject) {
                          el.srcObject = screenShareStreamObject;
                        }
                      }}
                    />
                  ))}
              </div>
            )}
            <div
              className={
                screenShareStream
                  ? "remote_videos_container_with_screenShare"
                  : "remote_videos_container"
              }
            >
              {peerPresent &&
                (showChatSection
                  ? remoteVideoList.slice(0, 7)
                  : remoteVideoList.slice(0, 10)
                )
                  .filter((streamID) => streamID.streamId !== screenShareStream)
                  .map((streamID, index) => (
                    <div
                      className={
                        peerPresent
                          ? "double_remote_video_container"
                          : "_single_remote_video_container"
                      }
                      style={{
                        width:
                          remoteVideoList.length === 1
                            ? "100%"
                            : remoteVideoList.length <= 3
                            ? screenShareStream !== null
                              ? "calc(100% - 2px)"
                              : "calc(50% - 2px )"
                            : remoteVideoList.length >= 3
                            ? screenShareStream !== null
                              ? "calc(100% - 2px)"
                              : showChatSection
                              ? "calc(33% - 2px)"
                              : "calc(25% - 2px )"
                            : "calc(25% - 2px )",
                        height:
                          remoteVideoList.length <= 2
                            ? screenShareStream !== null
                              ? "50%"
                              : "100%"
                            : remoteVideoList.length <= 8
                            ? screenShareStream !== null
                              ? "calc(25% - 2px)"
                              : "calc(50% - 2px )"
                            : remoteVideoList.length > 8
                            ? "calc(33% - 2px )"
                            : "calc(33% - 2px )",
                      }}
                    >
                      <video
                        key={streamID.streamId}
                        autoPlay
                        playsInline
                        style={{
                          transform:
                            streamID === screenShareStream ? "scaleX(1)" : "",
                        }}
                        ref={(el) => {
                          remoteVideoStream.current[index] = el;
                          if (index === remoteVideoList.length - 1) {
                            setRefsSet(true);
                          }
                        }}
                        className="remote_video"
                      >
                        <src></src>
                      </video>
                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "10px",
                          zIndex: "9",
                        }}
                      >
                        {streamID.name}
                      </div>
                      <div className="mute_mic_container">
                        <img
                          src={disableMic}
                          alt="mutemike"
                          className="mute_mic"
                        />
                      </div>
                    </div>
                  ))}

              <div
                style={{
                  display:
                    screenShareStream !== null &&
                    screenShareStream === localStreamId.current
                      ? "none"
                      : "",
                  width: peerPresent
                    ? remoteVideoList.length < 1
                      ? "100%"
                      : remoteVideoList.length <= 3
                      ? screenShareStream !== null
                        ? "calc(100% - 2px)"
                        : "calc(50% - 2px )"
                      : remoteVideoList.length >= 3
                      ? showChatSection
                        ? screenShareStream !== null
                          ? "width(100% - 2px)"
                          : "calc(33% - 2px)"
                        : "calc(25% - 2px )"
                      : "calc(25% - 2px )"
                    : "",
                  height: peerPresent
                    ? remoteVideoList.length < 1
                      ? "100%"
                      : remoteVideoList.length <= 8
                      ? "calc(50% - 2px )"
                      : remoteVideoList.length > 8
                      ? "calc(33% - 2px )"
                      : "calc(33% - 2px )"
                    : "",
                  transform: peerPresent
                    ? screenShareStream !== null
                      ? "scaleX(-1)"
                      : remoteVideoList.length === 0
                      ? "scaleX(-1)"
                      : remoteVideoList.length <= 2
                      ? " scaleX(-1) translateY(-95%)"
                      : "scaleX(-1)"
                    : " scaleX(-1)",
                }}
                className={
                  peerPresent
                    ? remoteVideoList.length === 0
                      ? "local_video_stream_peer_absent"
                      : remoteVideoList.length <= 2
                      ? screenShareStream !== null
                        ? "double_remote_video_container"
                        : "local_video_stream_peer_present"
                      : "double_remote_video_container"
                    : "local_video_stream_peer_absent"
                }
                id={"local-video"}
              >
                {" "}
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    zIndex: "9",
                    transform: "scaleX(-1)",
                  }}
                >
                  you
                </div>
                <div className="mute_mic_container" style={{ left: "12px" }}>
                  <img src={disableMic} alt="mutemike" className="mute_mic" />
                </div>
              </div>
              {showChatSection ? (
                remoteVideoList.length > 7 ? (
                  <div
                    className={
                      peerPresent
                        ? "double_remote_video_container"
                        : "_single_remote_video_container"
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "calc(33% - 2px)",
                      height: "calc(33% - 2px )",
                    }}
                  >
                    + {remoteVideoList.length - 7}Others
                  </div>
                ) : (
                  ""
                )
              ) : remoteVideoList.length > 10 ? (
                <div
                  className={
                    peerPresent
                      ? "double_remote_video_container"
                      : "_single_remote_video_container"
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "calc(25% - 2px)",
                    height: "calc(33% - 2px )",
                    color: "black",
                  }}
                >
                  {remoteVideoList.length - 10}+Others
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {peerPresent && showChatSection && (
            <div className="chat_section">
              <RightSection
                state={state}
                kickUserFromRoom={kickUserFromRoom}
                muteUser={muteUser}
                socketRef={socketRef.current} 
                messages={messages} setMessages={setMessages}
                userId={ myUserId.current}
                userName={myUserName.current}
              />
            </div>
          )}

          {callStarted && (
            <div className="call_actions_tab">
              <div
                className="call_action_button_icon_container"
                onClick={toggleAudio}
              >
                <img
                  src={MicroPhoneMuted ? disableMic : enabledMic}
                  alt="mike"
                  className="m-auto  w-6 h-6 cursor-pointer"
                />
              </div>
              <div
                className="call_action_button_icon_container"
                onClick={!speakerMuted ? startScreenShare : stopScreenSharing}
              >
                <LuScreenShare color=" white" size={"28px"} />
              </div>
              <div
                className="call_action_button_icon_container"
                onClick={endVideoCall}
              >
                <img
                  src={endcallButton}
                  alt="mike"
                  className="m-auto w-6 h-6 cursor-pointer"
                />
              </div>
              <div
                className="call_action_button_icon_container"
                onClick={ToggleVideo}
              >
                <img
                  src={videoMuted ? disableVideo : enabledVideo}
                  alt="mike"
                  className="m-auto w-5 h-5 cursor-pointer"
                />
              </div>
              <div
                className="call_action_button_icon_container"
                onClick={chatIconClick}
              >
                <IoIosChatboxes color="white" size="28px" />
              </div>
            </div>
          )}
          {acceptOrRejectPopup && (
            <div
              style={{
                display: "flex",
                position: "absolute",
                width: "300px",
                maxHeight: "400px",
                overflow: "auto",
                backgroundColor: "white",
                flexDirection: "column",
              }}
            >
              {requestList?.map((item) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100px",
                  }}
                >
                  {item.name} wants to join !
                  <div style={{ display: "flex", width: "100%" }}>
                    <div onClick={() => admitUserToRoom(item.id)}>
                      Acceptrequest
                    </div>
                    <div onClick={() => rejectUserRequestToJoinRoom(item.id)}>
                      RejectRequest
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {/* <div onClick={endVideoCall}>endcall</div> */}
        </>
      )}
    </div>
  );
};

export default Meeting;
