import React, { useEffect, useState } from "react";
import { DataTable } from "../../components/table/Data-Table";
import { testeColumns } from "./columns";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../config/baseUrl";
import axios from "../../config/axiosAuth";
import { AddTicket } from "./AddTicket";
import useGetAllTIckets from "../../hooks/testers/getTickets.hook";

const DetailedrojectTesterSheet = () => {
  const { id } = useParams();

  const [tickets, setTickets] = useState([]);
  const [testModule, setTestModule] = useState('');
  const[data,setData]=useState({
    projectId: id,
    module: testModule,
  })
//   const data={
//     projectId: id,
//     module: testModule,
//   }
const {ticketsByproject}=useGetAllTIckets(data)
  
  useEffect(() => {
   
    if(testModule!==''&&testModule!==undefined){
return
    }else{
        setTestModule(ticketsByproject?.project?.projectModules?.[0]);

    }
  }, [ticketsByproject]);


  useEffect(() => {
    setData((prev)=>({...prev,testModule:testModule}))
    // getTicketsByProjectId();
  }, [testModule]);
  return (
    <>
      <div className="flex justify-between mb-[20px] mt-[20px]">
        {ticketsByproject?.project?.projectName && (
          <div className="min-w-[50px] max-w-max-content bg-[#3ea7d6] flex items-center justify-center text-white h-[35px] p-4 rounded-[5px] ">
            {ticketsByproject?.project?.projectName}
          </div>
        )}
        <div className="flex ">
          {ticketsByproject?.project?.projectModules?.map((item, key) => {
            const isFirst = key === 0;
            const isLast = key === ticketsByproject?.project?.projectModules?.length - 1;
            return (
              <div
                onClick={() => setTestModule(item)}
                key={key}
                className="min-w-[50px] max-w-max-content border-[#3ea7d6] border-[1px] flex items-center cursor-pointer justifycenter text-white p-2 h-[35px]"
                style={{
                  backgroundColor: item === testModule ? "#3ea7d6" : "",
                  color: item === testModule ? "white" : "#3ea7d6",
                  borderTopLeftRadius: isFirst ? "6px" : "0px",
                  borderBottomLeftRadius: isFirst ? "6px" : "0px",
                  borderTopRightRadius: isLast ? "6px" : "0px",
                  borderBottomRightRadius: isLast ? "6px" : "0px",
                }}
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
      <div className="relative w-full">
        <div className="absolute right-[25px] top-[10px] font-bold ">
          <AddTicket testModule={testModule} />
        </div>
        <DataTable
          columns={testeColumns}
          data={ticketsByproject?.tickets || []}
          heading="Testing"
          defaultTheme={true}
          height={"90%"}
          className={"h-[85%]"}
        />
      </div>
    </>
  );
};

export default DetailedrojectTesterSheet;
