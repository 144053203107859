// import React, { useState } from "react";
// import {
//   Dialog,
//   DialogContent,
//   DialogTrigger,
// } from "../../components/ui/dialog";
// import moment from "moment";
// import axios from "../../config/axiosAuth";
// import { baseUrl } from "../../config/baseUrl";

// export const DailyLogs = ({ id }) => {
//   const [data, setData] = useState([]);

//   const handleDailyAttendance = () => {
//     const currentDate = new Date();

//     axios
//       .post(baseUrl + "/dailyAttendance", {
//         id: id,
//         date: currentDate,
//       })
//       .then((res) => {
//         setData([res.data.result]);
//       })
//       .catch((err) => {});
//   };
//   const formatTime = (time) => {
//     const totalMinutes = time;
//     const hours = Math.floor(totalMinutes / 60); // Get whole hours
//     const minutes = totalMinutes % 60;
//     return `${hours}:${minutes}`;
//   };
//   return (
//     <Dialog>
//       <DialogTrigger>
//         <button onClick={handleDailyAttendance} className="button-17">
//           View
//         </button>
//       </DialogTrigger>
//       <DialogContent style={{

//                 minwidth:'500px',
//                 width:'max-content',
//                 maxWidth:'max-content'
//               }}>
//          <div className="w-full h-full overflow-auto max-h-[450px] mt-[10px]">

// {data && (
//   <div>
//     <div
//       style={{
//         padding: "20px",
//         textAlign: "center",
//         paddingTop:0
//       }}
//     >
//       <table>
//         <thead className="sticky top-[-1px]">
//                   <tr
//                     style={{
//                       backgroundColor: "#f1f4f7",
//                       color: "gray",
//                     }}
//                   >
//                     <th>CheckIn</th>

//                     <th>CheckOut</th>
//                     <th>Duration</th>

//                   </tr>
//                 </thead>
//                 <tbody className="tableBody">

//                   {data?.map((log, index) => {
//                     const maxPairs = Math.max(
//                       log.loggedIn?.length || 0,
//                       log.loggedOut?.length || 0
//                     );

//                     const pairs = Array.from({ length: maxPairs }, (_, i) => ({
//                       loggedIn: log.loggedIn?.[i] || null,
//                       loggedOut: log.loggedOut?.[i] || null,
//                       duration:log.duration?.[i]||null,
//                     }));

//                     return pairs.map((pair, i) => (
//                       <tr key={`${index}-${i}`} className="border-b-[1px] border-[grey]">
//                         <td>
//                           {pair.loggedIn
//                             ? moment(pair.loggedIn).format("DD-MM-YYYY, LT")
//                             : "No CheckIn"}
//                         </td>

//                         <td>
//                           {pair.loggedOut
//                             ? moment(pair.loggedOut).format("DD-MM-YYYY, LT")
//                             : "No CheckOut"}
//                         </td>
//                         <td>{formatTime(pair.duration)} hrs</td>
//                       </tr>
//                     ));
//                   })}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         )}
//         </div>
//       </DialogContent>
//     </Dialog>
//   );
// };
import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import moment from "moment";
import axios from "../../config/axiosAuth";
import { baseUrl } from "../../config/baseUrl";
import { useParams } from "react-router-dom";
import { DescriptionModal } from "./DescriptionModal";
// import { baseUrlUser } from "../../../../../user/config/baseUrl";

export const DailyLogs = ({ id, url, monthly, date }) => {
  const [data, setData] = useState([]);

  const ID = useParams();
  const handleDailyAttendance = () => {
    const currentDate = new Date();
    const finalurl = baseUrl + "/dailyAttendance";
    let finalId = monthly ? ID?.id : id;
    axios
      .post(finalurl, {
        id: finalId,
        date: date ? date : currentDate,
      })
      .then((res) => {
        setData([res.data.result]);
      })
      .catch((err) => {});
  };
  const time = (time) => {
    const totalMinutes = time;
    const hours = Math.floor(totalMinutes / 60); // Get whole hours
    const minutes = totalMinutes % 60;
    return `${hours}:${minutes}`;
  };

  return (
    <Dialog>
      <DialogTrigger>
        <button onClick={handleDailyAttendance} className="button-17">
          View
        </button>
      </DialogTrigger>
      <DialogContent className="!max-w-fit !min-w-[80%]">
        <div className="w-full h-full overflow-auto max-h-[450px] mt-[10px]">
          {data && (
            <div>
              <div
                style={{
                  padding: "20px",
                  textAlign: "center",
                  paddingTop: 0,
                }}
              >
                <table>
                  <thead className="sticky top-[-1px]">
                    <tr
                      style={{
                        backgroundColor: "#f1f4f7",
                        color: "gray",
                      }}
                    >
                      <th>CheckIn</th>

                      <th>CheckOut</th>
                      <th>CheckIn Location</th>
                      <th>CheckOut Location</th>

                      <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody className="tableBody">
                    {data?.map((log, index) => {
                      const maxPairs = Math.max(
                        log.loggedIn?.length || 0,
                        log.loggedOut?.length || 0
                      );

                      const pairs = Array.from(
                        { length: maxPairs },
                        (_, i) => ({
                          loggedIn: log.loggedIn?.[i] || null,
                          loggedOut: log.loggedOut?.[i] || null,
                          duration: log.duration?.[i] || null,
                          loginLocation: log.loginLocation?.[i] || null,
                          logoutLocation: log.logoutLocation?.[i] || null,
                        })
                      );

                      return pairs.map((pair, i) => (
                        <tr key={`${index}-${i}`}>
                          <td>
                            {pair.loggedIn
                              ? moment(pair.loggedIn).format("DD-MM-YYYY, LT")
                              : "No CheckIn"}
                          </td>

                          <td>
                            {pair.loggedOut
                              ? moment(pair.loggedOut).format("DD-MM-YYYY, LT")
                              : "No CheckOut"}
                          </td>
                          <td>
                            {/* {pair?.loginLocation?pair?.loginLocation:'NO Data found' } */}
                            {pair?.loginLocation?pair?.loginLocation?.length > 14 ? (
                              <DescriptionModal
                                trigger={
                                  <span className="flex w-[100px] overflow-hidden truncate">{`${pair?.loginLocation?.substring(
                                    0,
                                    14
                                  )}....`}</span>
                                }
                                data={pair?.loginLocation}
                              />
                            ) : (
                              pair?.loginLocation
                            ):'-'}
                          </td>
                          <td>
                            {/* {pair?.logoutLocation? pair?.logoutLocation:"NO Data found"} */}
                            {pair?.logoutLocation?pair?.logoutLocation?.length > 14 ? (
                              <DescriptionModal
                                trigger={
                                  <span className="flex w-[100px] overflow-hidden truncate">{`${pair?.logoutLocation?.substring(
                                    0,
                                    14
                                  )}....`}</span>
                                }
                                data={pair?.logoutLocation}
                              />
                            ) : (
                              pair?.logoutLocation
                            ):'-'}
                          </td>
                          <td>{pair?.duration}</td>
                        </tr>
                      ));
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
