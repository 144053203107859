import { DeleteIcon, Edit, Trash } from "lucide-react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import useAllocationHook from "../../hooks/useAllocationHook";
import CommonAlert from "../../components/ui/CommonAlert";
import { DescriptionModal } from "../../components/modals/DescriptionModal";
import useAssignTaskHook from "../../hooks/AssignTaskHook";
const NaviagteToEdit = ({ id }) => {
  const navigate = useNavigate();
  const goToEdit = () => {
    navigate(`/edit-assign-task/${id}`,{state:{edit:true}});
  };
  return (
    <div className="w-full flex items-center justify-center">
      <Edit color='blue'onClick={goToEdit} />
    </div>
  );
};
const ViewMembers = ({ id ,shouldEdit}) => {
  const navigate = useNavigate();
  const goToEdit = () => {
    navigate(`/editAllocation/${id}`,{state:{edit:false}});
  };
  return (
    <button className="button-17 !rounded-[10px] "onClick={goToEdit}>View</button>
  );
};


const Delete = ({ id }) => {
  const { DeleteTaskMutation } = useAssignTaskHook();
  const deleteAllocation = async () => {
    await DeleteTaskMutation.mutate(id);
  };
  return (
    <CommonAlert
    trigger={<Trash style={{ color: "red", margin: "auto" }} />}
    onClick={() => deleteAllocation()}
    heading={"Delete"}
    text={"Are you sure you want to delete ?"}
  />
);
    
  
};

export const Columns = [
  { header: "S.No", cell: ({ row }) => row.index + 1 },
  {
    header: "Task ",
    cell: ({ row }) => <DescriptionModal trigger={ `${row.original?.task.substring(0,7)}...`} data={row.original?.task}/>
  },
  {
    header: "Department",
    cell: ({ row }) => moment(row.original.createdAt).format("DD-MM-YYYY"),
  },
//   {
//     header: "View Members",
//     cell: ({ row }) => <ViewMembers  id={row?.original?._id} shouldEdit={false}/>
//   },

  {
    header: "Edit",
    cell: ({ row }) => <NaviagteToEdit id={row?.original?._id}  />,
  },
  {
    header: "Delete",
    cell: ({ row }) => <Delete id={row?.original?._id} />,
  },
  // {header:'FullDay',
  //   cell:({row})=>row.allDay===true?'yes':'No'
  // },
];
